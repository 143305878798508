import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'

import Loader from 'components/loader'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import DtiCalculator from 'components/dti-calculator/index'
import {
  components,
  assets,
  colors,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import {
  AMORTIZATION_TYPES,
  LOAN_APPLICATION_STATES_IDS,
} from 'modules/loan-application/constants'
import { useTranslation } from 'react-i18next'
import {
  isLevelPayment,
  isMinimumPayment,
  isSpecialCaseBank,
} from 'common/utils'

import EvaluationSessionsHistory from './evaluation-history'
import LoanDecisionCheckList from './loan-check-list'
import {
  getLoanApplication,
  getEvaluationSessions,
  evaluateDifferentLoan,
  getAssociatedLoanProducts,
  chooseLoan,
  nextStep,
  getSelfServiceCriterias,
} from '../actions'
import { LOAN_DECISION_STATUS, STEPS_MAP } from '../constants'

import styles from './style.module.scss'
import { calculateApr, calculateRate } from '../utils'
import SelectLoanCard from './loan-card'
import LoanBlockedModal from './loan-blocked-modal'
import ContactModal from './contact-modal'

const { Autocomplete, Dialog } = components
const { ChevronRightIcon } = assets
const { Unstable_ToggleButtonGroup: ToggleButtonGroup } = unstable_components

const SelectLoan = ({
  dispatch,
  loanApplicationId,
  selectedLoanProductId,
  userData,
  scrollToTop,
  loanApplicationStateId,
  hasCoBorrower,
  reEvaluationCount,
  isCommercialApp,
}) => {
  const navigate = useNavigate()
  const { t: translate } = useTranslation()
  const { state } = useStore()
  const { loanFormData } = state.flow
  const TABS = {
    CURRENT: translate('loanApplication.step2.currentEvaluation'),
    HISTORY: translate('loanApplication.step2.evaluationHistory'),
  }
  const [ausDetailsOpened, setAusDetailsOpened] = useState(null)
  const [selfServiceCriterias, setSelfServiceCriterias] = useState({
    selfServiceCriterias: [],
  })
  const CURRENT_HISTORY_TABS = [
    {
      id: TABS.CURRENT,
      children: TABS.CURRENT,
      variant: 'text',
      color: colors.grey[700],
      activeColor: 'white',
      style: { width: '50%' },
    },
    {
      id: TABS.HISTORY,
      children: TABS.HISTORY,
      variant: 'text',
      color: colors.grey[700],
      activeColor: 'white',
      style: { width: '50%' },
    },
  ]
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState(TABS.CURRENT)
  const [
    confirmModalSelectedLoanProduct,
    setConfirmedModalSelectedLoanProduct,
  ] = useState(null)
  const [locallySelectedLoanProductId, setLocallySelectedLoanProductId] =
    useState(selectedLoanProductId)
  const [evaluationSessionsHistory, setEvaluationSessionsHistory] = useState([])
  const [currentLoans, setCurrentLoans] = useState([])
  const loansLoaded = useRef(false)
  const [associatedLoanProducts, setAssociatedLoanProducts] = useState([])
  const [newSelectedProduct, setNewSelectedProduct] = useState(null)
  const [evaluatedLoanIdForDti, setEvaluatedLoanIdForDti] = useState(null)
  const [agreementsModalUCU, setAgreementsModalUCU] = useState({
    isVisible: false,
    loanProductId: null,
  })
  const translations = {
    noLoans: translate('loanApplication.step2.noLoans'),
    termInMonths: translate('loanApplication.step2.termInMonths'),
    rate: translate('loanApplication.step2.rate'),
    promotionalPeriod: translate('loanApplication.step2.promotionalPeriod'),
    promotionalPayment: translate('loanApplication.step2.promotionalPayment'),
    promotionalTypeName: translate('loanApplication.step2.promotionalTypeName'),
    apr: translate('loanApplication.step2.apr'),
    noPaydown: translate('loanApplication.step2.noPaydown'),
    withAutoPay: translate('loanApplication.step2.withAutoPay'),
    withoutAutoPay: translate('loanApplication.step2.withoutAutoPay'),
    asLowAs: translate('loanApplication.step2.asLowAs'),
    reEvaluate: translate('loanApplication.step2.reEvaluate'),
    reEvaluateForDifferent: translate(
      'loanApplication.step2.reEvaluateForDifferent'
    ),
    confirmSelectedLoan: translate('loanApplication.step2.confirmSelectedLoan'),
    areYouSure1: translate('loanApplication.step2.areYouSureLoanProduct1'),
    areYouSure2: translate('loanApplication.step2.areYouSureLoanProduct2'),
  }
  const canOverrideDecision = hasAccess(
    userData,
    CLAIMS.CAN_OVERRIDE_EVALUATIONS
  )
  const canEvaluateDifferentLoan = hasAccess(
    userData,
    CLAIMS.CAN_EVALUATE_DIFFERENT_LOAN
  )

  const isLoanProductFromUCU =
    confirmModalSelectedLoanProduct?.lenderId === process.env.REACT_APP_UCU &&
    hasCoBorrower

  const canViewHistorical = hasAccess(
    userData,
    CLAIMS.CAN_VIEW_EVALUATION_HISTORY
  )

  /**
   * Fetch decisions for Loan Products
   * @param {Boolean} [updateAusDecisions] if provided, it also updates the ausDetailsOpened modal with the newly retrieved data
   */
  const fetchDecisioning = (updateAusDecisions = false) =>
    getEvaluationSessions(loanApplicationId)
      .then((result) => {
        loansLoaded.current = true
        if (result?.length) {
          // The last Evaluation is the Current one
          const currentEvaluation =
            result[result.length - 1]?.evaluatedLoanProducts
          setCurrentLoans(currentEvaluation)
          setEvaluationSessionsHistory(result.slice(0, -1))

          if (updateAusDecisions && ausDetailsOpened) {
            const updatedLoan = currentEvaluation.find(
              (el) =>
                el.evaluatedLoanProductId ===
                ausDetailsOpened.loan.evaluatedLoanProductId
            )
            if (updatedLoan) {
              setAusDetailsOpened({ ...ausDetailsOpened, loan: updatedLoan })
            }
          }
        }
      })
      .catch(console.error)

  useEffect(() => {
    handleGetSelfServiceCriterias()
  }, [])

  const handleGetSelfServiceCriterias = () => {
    getSelfServiceCriterias(loanApplicationId).then((res) => {
      if (res) {
        setSelfServiceCriterias(res)
      } else {
        setSelfServiceCriterias({
          selfServiceCriterias: [],
        })
      }
    })
  }

  /** On mount fetch decisioning */
  useEffect(() => {
    setLoading(true)
    const promises = [fetchDecisioning()]
    promises.push(
      getAssociatedLoanProducts(loanApplicationId).then(
        setAssociatedLoanProducts
      )
    )
    Promise.all(promises).finally(() => setLoading(false))
  }, [reEvaluationCount])

  useEffect(() => {
    const decisionResultPassed = currentLoans.some(
      (loan) => loan.decisionResultId !== 0
    )
    if (
      decisionResultPassed &&
      loanApplicationStateId !==
        LOAN_APPLICATION_STATES_IDS.APPLICATION_EVALUATED
    ) {
      getLoanApplication(dispatch, loanApplicationId)
    } else {
      if (
        loanApplicationStateId ===
        LOAN_APPLICATION_STATES_IDS.APPLICATION_EVALUATED
      ) {
        getLoanApplication(dispatch, loanApplicationId)
      }
    }
  }, [currentLoans])

  const showDeclineModal = useMemo(() => {
    return (
      selfServiceCriterias.selfServiceCriterias?.length !== 0 &&
      !canOverrideDecision
    )
  }, [currentLoans, loansLoaded, canOverrideDecision, selfServiceCriterias])

  const showContactUsModal = useMemo(() => {
    return (
      (selfServiceCriterias.selfServiceCriterias?.find(
        (itm) => itm.criteriaSelfServiceType.id === 4
      ) &&
        !canOverrideDecision) ||
      (selfServiceCriterias.selfServiceCriterias?.length === 0 &&
        !currentLoans.length &&
        !canOverrideDecision)
    )
  }, [currentLoans, loansLoaded, canOverrideDecision, selfServiceCriterias])

  useEffect(() => {
    return () => {
      if (showDeclineModal) {
        document.body.scrollTo({ behavior: 'smooth', top: 0 })
      }
    }
  }, [showDeclineModal])

  const dtiClicked = (event, selectedEvaluatedLoan) => {
    event.stopPropagation()
    setEvaluatedLoanIdForDti(selectedEvaluatedLoan.evaluatedLoanProductId)
  }

  /** Select Loan handler -> saves the new Loan selection and moves to next step */
  const selectLoan = (loanProductId) => {
    setLoading(true)
    setConfirmedModalSelectedLoanProduct(null)
    scrollToTop()
    chooseLoan(dispatch, loanApplicationId, loanProductId)
      .then(() => {
        setLoading(false)
        // If saving is successful move to the next step
        nextStep(dispatch, navigate, STEPS_MAP.SELECT_LOAN, isCommercialApp)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  /** Re-evaluate handler -> evaluates with another loan product and re-fetches data in order to update the state of the Loan Application */
  const reEvaluate = () => {
    setLoading(true)
    evaluateDifferentLoan(loanApplicationId, newSelectedProduct.guid)
      .then(async () => {
        setNewSelectedProduct(null)
        await fetchDecisioning()
        await getLoanApplication(dispatch, loanApplicationId)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  /** Triggered when clicking on Continue to Agreements */
  const onContinueToAgreementsClicked = (loan, confirmed) => {
    const { loanProductId, lenderId } = loan.loanProduct

    // Handle UCU specific modal
    if (process.env.REACT_APP_UCU === lenderId && hasCoBorrower && !confirmed) {
      setAgreementsModalUCU({
        isVisible: true,
        loan: loan,
      })
      return
    }

    // If the Loan Product was not changed, continue to agreements
    if (
      loanProductId === selectedLoanProductId &&
      !(
        loanApplicationStateId ===
          LOAN_APPLICATION_STATES_IDS.APPLICATION_EVALUATED ||
        loanApplicationStateId === LOAN_APPLICATION_STATES_IDS.EVALUATION_FAILED
      )
    ) {
      nextStep(dispatch, navigate, STEPS_MAP.SELECT_LOAN, isCommercialApp)
      return
    }

    // Else show the confirmation modal
    setConfirmedModalSelectedLoanProduct(loan.loanProduct)
  }

  if (loading) {
    return <Loader size={130} center={true} />
  }

  return (
    <div className={styles.selectLoanWrapper}>
      {evaluatedLoanIdForDti && (
        <DtiCalculator
          loanApplicationId={loanApplicationId}
          evaluatedLoanProductId={evaluatedLoanIdForDti}
          borrowerId={loanFormData.borrowerGeneralDetails?.applicantId}
          coBorrowerId={loanFormData.coBorrowerGeneralDetails?.applicantId}
          onClose={() => setEvaluatedLoanIdForDti(null)}
        />
      )}

      {canViewHistorical && (
        <ToggleButtonGroup
          buttons={CURRENT_HISTORY_TABS}
          active={tab}
          onChange={(selected) => setTab(selected)}
          style={{ width: '100%', background: colors.grey[25] }}
        />
      )}

      {tab === TABS.CURRENT ? (
        <div className={styles.cardsWrapper}>
          {currentLoans.map((loan, idx) => {
            const { loanProduct, calculatedFinancingDetails } = loan
            const isFailed = loan.decisionResultId === LOAN_DECISION_STATUS.FAIL
            const showOverriddenAlert = loan.hasOverriddenDecisions
            const isSpecialBank = isSpecialCaseBank(loanProduct.lenderId)
            const isLevel = isLevelPayment(loanProduct)
            const isMinimum = isMinimumPayment(loanProduct)

            const defaultPaymentValueWithPromo = _getPaymentValue(
              calculatedFinancingDetails,
              AMORTIZATION_TYPES.SIMPLE,
              true
            )

            const defaultPaymentValueWithoutPromo = _getPaymentValue(
              calculatedFinancingDetails,
              AMORTIZATION_TYPES.SIMPLE,
              false
            )

            // Values for With AutoPay
            const autoPayPaydown = isMinimum
              ? _getPaymentValue(
                  calculatedFinancingDetails,
                  AMORTIZATION_TYPES.MINIMUM_PAYMENT_WITH_DISCOUNTED_INTREST_RATE,
                  false
                )
              : _getPaymentValue(
                  calculatedFinancingDetails,
                  AMORTIZATION_TYPES.INTEREST_RATE_DISCOUNT,
                  true
                )

            const autoPayNoPaydown = isSpecialBank
              ? defaultPaymentValueWithoutPromo
              : _getPaymentValue(
                  calculatedFinancingDetails,
                  AMORTIZATION_TYPES.INTEREST_RATE_DISCOUNT,
                  false
                )
            const autoPayPromo = isLevel
              ? autoPayPaydown
              : defaultPaymentValueWithPromo

            // Values for Without AutoPay
            const noAutoPayPromo = defaultPaymentValueWithPromo
            const noAutoPayPaydown = isMinimum
              ? _getPaymentValue(
                  calculatedFinancingDetails,
                  AMORTIZATION_TYPES.MINIMUM_PAYMENT,
                  false
                )
              : defaultPaymentValueWithPromo

            const noAutoPayNoPaydown = isSpecialBank
              ? _getPaymentValue(
                  calculatedFinancingDetails,
                  AMORTIZATION_TYPES.INTEREST_RATE_FEE,
                  false
                )
              : defaultPaymentValueWithoutPromo

            const interestRateWithoutAutopay = calculateRate(
              calculatedFinancingDetails,
              loanProduct.lenderId,
              null,
              isSpecialBank ? loanProduct.interestRateFee : 0
            )

            const autoPayDiscount = loanProduct.interestRateDiscount
            const interestRate = autoPayDiscount
              ? calculateRate(
                  calculatedFinancingDetails,
                  loanProduct.lenderId,
                  autoPayDiscount
                )
              : calculateRate(
                  calculatedFinancingDetails,
                  loanProduct.lenderId,
                  null,
                  isSpecialBank ? 0 : loanProduct.interestRateFee
                )

            const apr = calculateApr(
              calculatedFinancingDetails,
              loanProduct.lenderId
            )
            const isSelected =
              locallySelectedLoanProductId === loan.loanProductId

            return (
              <SelectLoanCard
                key={idx}
                isSpecialBank={isSpecialBank}
                isFailed={isFailed}
                showOverriddenAlert={showOverriddenAlert}
                isSelected={isSelected}
                isDifferentLoanProduct={
                  selectedLoanProductId &&
                  locallySelectedLoanProductId !== selectedLoanProductId
                }
                loan={loan}
                loanProduct={loan.loanProduct}
                onDTIClicked={(ev) => dtiClicked(ev, loan)}
                onLoanSelected={() =>
                  !isSelected &&
                  setLocallySelectedLoanProductId(loan.loanProductId)
                }
                onContinueClicked={onContinueToAgreementsClicked}
                userData={userData}
                onAusDetailsClicked={(ev) => {
                  ev.stopPropagation()
                  setAusDetailsOpened({ loan, idx })
                }}
                calculations={{
                  interestRate,
                  interestRateWithoutAutopay,
                  paymentWithAutoPay: autoPayNoPaydown,
                  paymentWithAutoPayAndPaydown: autoPayPaydown,
                  paymentNoAutoPay: noAutoPayNoPaydown,
                  paymentNoAutoPayAndPaydown: noAutoPayPaydown,
                  promoPeriod: _getPaymentPeriod(
                    calculatedFinancingDetails,
                    AMORTIZATION_TYPES.SIMPLE,
                    true
                  )?.months,
                  promoPayment: autoPayPromo,
                  promoPaymentWithoutAutopay: noAutoPayPromo,
                  apr,
                }}
              />
            )
          })}
        </div>
      ) : (
        <EvaluationSessionsHistory
          evaluationSessionsHistory={evaluationSessionsHistory}
          dtiClicked={dtiClicked}
        />
      )}

      {tab === TABS.CURRENT && canEvaluateDifferentLoan && (
        <div className={styles.reEvaluate}>
          <Autocomplete
            label={translations.reEvaluate}
            options={associatedLoanProducts?.map((el) => ({
              guid: el?.loanProductId,
              name: el?.name,
              label: el?.name,
            }))}
            onChange={(e, selected) => {
              setNewSelectedProduct(selected)
            }}
            sx={{ flexGrow: 1 }}
          />

          <Button
            onClick={reEvaluate}
            disabled={!newSelectedProduct}
            sx={{ margin: '28px 0 0 24px', height: '40px' }}
            endIcon={<ChevronRightIcon />}
          >
            {translations.reEvaluate}
          </Button>
        </div>
      )}

      {/* Confirm choosing Loan Product Modal */}
      <Dialog
        open={!!confirmModalSelectedLoanProduct?.loanProductId}
        onClose={() => setConfirmedModalSelectedLoanProduct(null)}
        title={translations.confirmSelectedLoan}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '400px',
            '& .MuiDialogTitle-root': {
              padding: '24px 24px 0',
              fontSize: '1.125rem',
            },
            '& .MuiDialogActions-root': {
              paddingTop: '0',
            },
          },
        }}
        actionsProps={{
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            '& button': {
              width: '50%',
            },
          },
        }}
        actions={
          <>
            <Button
              onClick={() => setConfirmedModalSelectedLoanProduct(null)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {isLoanProductFromUCU
                ? UCU_VERBIAGE.DECLINE
                : translate('buttons.cancel')}
            </Button>
            <Button
              onClick={() =>
                selectLoan(confirmModalSelectedLoanProduct?.loanProductId)
              }
            >
              {isLoanProductFromUCU
                ? UCU_VERBIAGE.ACKNOWLEDGE
                : translate('buttons.confirm')}
            </Button>
          </>
        }
      >
        <div
          className="delete-modal__question"
          style={{ marginTop: '20px', fontSize: '0.875rem' }}
        >
          {isLoanProductFromUCU ? (
            UCU_VERBIAGE.DESCRIPTION
          ) : (
            <div>
              {translations.areYouSure1}
              <span style={{ color: colors.blue[500], fontWeight: 600 }}>
                &nbsp;{confirmModalSelectedLoanProduct?.name}
              </span>
              .&nbsp;{translations.areYouSure2}
            </div>
          )}
        </div>
      </Dialog>

      {/** Loan Decisions Checklist */}
      {!!ausDetailsOpened && (
        <Dialog
          open={true}
          title={
            <div className={styles.ausDetailsTitle}>
              {translate('loanApplication.step2.ausDetails')}
            </div>
          }
          titleProps={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 24px',
          }}
          PaperProps={{
            sx: {
              maxWidth: '100%',
              width: '800px',
              margin: 0,
              padding: '10px 0',
              '& .MuiDialogContent-root': {
                padding: '0 0 24px',
              },
              '& .check-list__item': {
                padding: '16px 24px',
                margin: 0,
              },
            },
          }}
          onClose={() => setAusDetailsOpened(null)}
        >
          <LoanDecisionCheckList
            userData={userData}
            checks={ausDetailsOpened?.loan.evaluatedLoanDecisions}
            loanIndex={ausDetailsOpened?.idx}
            fetchDecisioning={() => fetchDecisioning(true)}
            currentLoans={currentLoans}
          />
        </Dialog>
      )}

      {showDeclineModal && !showContactUsModal && (
        <LoanBlockedModal
          dispatch={dispatch}
          loanApplicationId={loanApplicationId}
          loanFormData={loanFormData}
          selfServiceCriterias={selfServiceCriterias}
          handleGetSelfServiceCriterias={handleGetSelfServiceCriterias}
        />
      )}

      {showContactUsModal && <ContactModal />}

      {/** UCU agreements modal */}
      <Dialog
        confirmButton={UCU_VERBIAGE.ACKNOWLEDGE}
        cancelButton={UCU_VERBIAGE.DECLINE}
        onClose={() =>
          setAgreementsModalUCU({
            isVisible: false,
            loanProductId: null,
          })
        }
        actions={
          <>
            <Button
              onClick={() =>
                setAgreementsModalUCU({
                  isVisible: false,
                  loanProductId: null,
                })
              }
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button
              onClick={() => {
                onContinueToAgreementsClicked(agreementsModalUCU?.loan, true)
                setAgreementsModalUCU({
                  isVisible: false,
                  loan: null,
                })
              }}
            >
              {translate('buttons.confirm')}
            </Button>
          </>
        }
        open={agreementsModalUCU?.isVisible}
      >
        <div className="delete-modal__question" style={{ marginTop: '20px' }}>
          {UCU_VERBIAGE.DESCRIPTION}
        </div>
      </Dialog>
    </div>
  )
}

function _getPaymentPeriod(
  calculatedFinancingDetails,
  amortizationTypeId,
  isPromo = false
) {
  return calculatedFinancingDetails?.paymentPeriods
    .find((item) => item.amortizationType.id === amortizationTypeId)
    ?.paymentMonths.find((item) => item.isPromotionalPeriod === isPromo)
}

function _getPaymentValue(
  calculatedFinancingDetails,
  amortizationTypeId,
  isPromo = false
) {
  const period = _getPaymentPeriod(
    calculatedFinancingDetails,
    amortizationTypeId,
    isPromo
  )
  if (!period) {
    return null
  }
  return parseFloat(period.monthlyPayment).toFixed(2)
}

SelectLoan.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  selectedLoanProductId: PropTypes.string,
  userData: PropTypes.object.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  loanApplicationStateId: PropTypes.number.isRequired,
  hasCoBorrower: PropTypes.bool,
  reEvaluationCount: PropTypes.number.isRequired,
  isCommercialApp: PropTypes.bool,
}

export default SelectLoan

const UCU_VERBIAGE = {
  ACKNOWLEDGE: 'I acknowledge',
  DECLINE: 'I do not acknowledge',
  DESCRIPTION:
    'I hereby attest that I am resident of the service address. I acknowledge and understand as a co-applicant I have an obligation and I am equally responsible for the terms and repayment of the loan.',
}
