import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { formatAddress } from 'common/utils'
import TextField, { INPUT_TYPES } from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button/index'
import {
  getOrganizations,
  getUsersMinimalData,
} from 'modules/organizations/actions'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { VALIDATION_TYPES, validate } from 'components/validator/index'
import {
  getLoanApplication,
  submitSummary,
} from 'modules/loan-application/actions'
import { LOAN_STATUS_ID_MAPPING } from 'modules/loan-applications/constants'
import { ACH_STATUS } from 'modules/loan-application/constants'
import StatusComponent from 'components/status'
import { setLoading, showNotification } from 'modules/global/actions'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import { formatIntToCurrency } from 'modules/loan-application/utils'

import styles from './style.module.scss'

const { Dialog, Card, Dropdown, Autocomplete } = components

const LoanSummary = ({
  isCommercialApp,
  borrowerDetails,
  coBorrowerDetails,
  serviceAddress,
  userData,
  loanData,
  setIsSummaryModalOpen,
  areInputsDisabled,
  isSummaryModalOpen,
  achStatus,
  dispatch,
}) => {
  const { t: translate } = useTranslation()

  const hasOrgAccess = hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS)
  const canChangeOrg = hasAccess(userData, CLAIMS.CAN_CHANGE_DEALERS)
  const [organizations, setOrganizations] = useState([])
  const [salesRep, setSalesRep] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [selectedSalesRep, setSelectedSalesRep] = useState({})
  const [borrowerEmail, setBorrowerEmail] = useState(
    borrowerDetails.emailAddress
  )
  const [coBorrowerEmail, setCoBorrowerEmail] = useState(
    coBorrowerDetails?.emailAddress || ''
  )
  const [errors, setErrors] = useState({})

  const businessOwnerTranslation = translate(
    'loanApplication.commercial.businessOwner'
  )

  const achStatusText =
    achStatus !== null && achStatus !== undefined
      ? translate(`loanApplication.statusCard.achStatus.status.${achStatus}`)
      : translate(
          `loanApplication.statusCard.achStatus.status.${ACH_STATUS.INCOMPLETE}`
        )

  const salesRepOptions = useMemo(() => {
    return salesRep.map((rep) => {
      return {
        ...rep,
        id: rep.guid,
        label: rep.value,
      }
    })
  }, [salesRep])

  const organizationsOptions = useMemo(() => {
    return organizations.map((org) => {
      return {
        ...org,
        id: org.guid,
        label: org.value,
      }
    })
  }, [organizations])

  useEffect(() => {
    if (hasOrgAccess) {
      getOrganizations(null, { itemsPerPage: 1000 }).then((res) => {
        const { organizationUnits } = res
        const salesOrganizations = organizationUnits
          .filter((o) => o.type.id === ORGANIZATION_TYPE_IDS.DEALER)
          .map((item) => {
            return {
              value: item.name,
              guid: item.guid,
            }
          })

        setOrganizations(salesOrganizations)

        const selectedOrg = salesOrganizations.find((item) => {
          return item.guid === loanData.salesOrganizationId
        })

        if (selectedOrg) {
          setSelectedOrganization(selectedOrg)
        } else {
          setSelectedOrganization('')
        }
      })
    }
  }, [isSummaryModalOpen])

  useEffect(() => {
    if (selectedOrganization) {
      getUsersMinimalData(selectedOrganization.guid).then((res) => {
        setSalesRep(
          res.map((item) => {
            if (item.guid === loanData.salesRepresentativeId) {
              setSelectedSalesRep({
                value: loanData.salesRepresentativeName,
                guid: loanData.salesRepresentativeId,
                phoneNumber: item.phoneNumber,
                email: item.emailAddress,
              })
            }
            return {
              value: item.name,
              guid: item.guid,
              phoneNumber: item.phoneNumber,
              email: item.emailAddress,
            }
          })
        )
      })
    }
  }, [selectedOrganization, isSummaryModalOpen])

  const handleSubmit = () => {
    const organizationId = selectedOrganization.guid
    const salesRepId = selectedSalesRep.guid
    const [isValid, errors] = validate(
      {
        organizationId: [VALIDATION_TYPES.REQUIRED],
        salesRepId: [VALIDATION_TYPES.REQUIRED],
      },
      { organizationId, salesRepId }
    )

    if (!isValid) {
      return setErrors(errors)
    } else {
      const applicantsBasicInfo = [
        {
          emailAddress: borrowerEmail,
          applicantTypeId: borrowerDetails.applicantTypeId,
        },
        ...(coBorrowerDetails
          ? [
              {
                emailAddress: coBorrowerEmail,
                applicantTypeId: coBorrowerDetails.applicantTypeId,
              },
            ]
          : []),
      ]
      setIsSummaryModalOpen(false)
      setLoading(dispatch, true)
      submitSummary({
        loanApplicationId: loanData.loanApplicationId,
        salesOrganizationId:
          selectedOrganization.guid || loanData.salesOrganizationId,
        salesRepresentativeId:
          selectedSalesRep.guid || loanData.salesRepresentativeId,
        applicantsBasicInfo,
      })
        .then(() => {
          getLoanApplication(dispatch, loanData.loanApplicationId)

          showNotification(dispatch, {
            title: `Successfully updated info for loan ${loanData.loanApplicationNumber}`,
          })
        })
        .finally(() => setLoading(dispatch, false))
    }
  }

  const renderOwnersFullNames = () =>
    loanData.businessOwners.map((owner, index) => (
      <React.Fragment key={index}>
        <TextField
          label={`${businessOwnerTranslation} #${index + 1}`}
          disabled
          value={`${owner.firstName} ${owner.lastName}`}
          onChange={() => {}}
        />
      </React.Fragment>
    ))

  const renderUserName = (userDetails, isCoBorrower = false) => {
    const fullName = `${userDetails.firstName} ${userDetails.lastName}`

    return (
      <TextField
        label={translate(
          `loanApplication.statusCard.summaryModal.${
            isCoBorrower ? 'coborrowerName' : 'borrowerName'
          }`
        )}
        disabled
        value={fullName}
        onChange={() => {}}
      />
    )
  }

  return (
    <Dialog
      open={isSummaryModalOpen}
      onClose={() => setIsSummaryModalOpen(false)}
      title="Edit info"
      actions={
        <>
          <Button
            onClick={() => setIsSummaryModalOpen(false)}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button disabled={!canChangeOrg} onClick={handleSubmit}>
            {translate('buttons.save')}
          </Button>
        </>
      }
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '800px',
        },
      }}
    >
      <div>
        <Card>
          <div className="applicant-info__form-title">
            {translate(
              'loanApplication.statusCard.summaryModal.borrowerDetails'
            )}
          </div>

          <div className={styles.borrowerCard}>
            {isCommercialApp
              ? renderOwnersFullNames()
              : renderUserName(borrowerDetails)}

            <TextField
              label={translate(
                'loanApplication.statusCard.summaryModal.emailAddress'
              )}
              value={isCommercialApp ? loanData.emailAddress : borrowerEmail}
              disabled={!hasAccess(userData, [CLAIMS.CAN_EDIT_APPLICANT_EMAIL])}
              onChange={setBorrowerEmail}
            />

            <TextField
              label={translate(
                'loanApplication.statusCard.summaryModal.phoneNumber'
              )}
              value={
                isCommercialApp
                  ? loanData.mobilePhone
                  : borrowerDetails.phoneNumber
              }
              disabled
              type={INPUT_TYPES.PHONE}
              onChange={() => {}}
            />
            <TextField
              label={translate(
                'loanApplication.statusCard.summaryModal.serviceAddress'
              )}
              value={formatAddress(serviceAddress)}
              disabled
              onChange={() => {}}
            />
            {!isCommercialApp && (
              <Dropdown
                disabled
                options={[]}
                className="loan-form__dropdown"
                label={translate(
                  'loanApplication.statusCard.summaryModal.preferredMethodOfCommunication'
                )}
                onChange={() => {}}
                value={{
                  label: translate(
                    `loanApplication.step1.preferredMethodOfCommunication.${borrowerDetails.preferredCommunicationMethod.id}`
                  ),
                  id: borrowerDetails.preferredCommunicationMethod.id,
                }}
              />
            )}
          </div>
        </Card>

        {coBorrowerDetails ? (
          <Card sx={{ mt: '24px' }}>
            <div className="applicant-info__form-title">
              {translate('loanApplication.common.coborrowerDetails')}
            </div>
            <div className={styles.borrowerCard}>
              {renderUserName(coBorrowerDetails, true)}
              <TextField
                label={translate(
                  'loanApplication.statusCard.summaryModal.emailAddress'
                )}
                value={coBorrowerEmail}
                disabled={
                  !hasAccess(userData, [CLAIMS.CAN_EDIT_APPLICANT_EMAIL])
                }
                onChange={setCoBorrowerEmail}
              />

              <TextField
                label={translate(
                  'loanApplication.statusCard.summaryModal.phoneNumber'
                )}
                value={coBorrowerDetails.phoneNumber}
                disabled
                type={INPUT_TYPES.PHONE}
                onChange={() => {}}
              />
            </div>
          </Card>
        ) : null}

        {hasOrgAccess ? (
          <Card sx={{ mt: '24px' }}>
            <div className="applicant-info__form-title">
              {translate(
                'loanApplication.statusCard.summaryModal.salesRepDetails'
              )}
            </div>

            <div className={styles.salesRepCard}>
              <Autocomplete
                label={translate(
                  'loanApplication.statusCard.summaryModal.salesRepName'
                )}
                options={salesRepOptions}
                value={
                  selectedSalesRep
                    ? { ...selectedSalesRep, label: selectedSalesRep.value }
                    : ''
                }
                onChange={(e, selected) => {
                  setSelectedSalesRep(selected)
                }}
                error={errors.salesRepId}
                disabled={
                  !selectedOrganization || !canChangeOrg || areInputsDisabled
                }
              />

              <TextField
                label={translate(
                  'loanApplication.statusCard.summaryModal.salesRepresentativeEmail'
                )}
                value={selectedSalesRep?.email}
                disabled
                onChange={() => {}}
              />

              <TextField
                label={translate(
                  'loanApplication.statusCard.summaryModal.salesRepresentativePhoneNumber'
                )}
                value={selectedSalesRep?.phoneNumber}
                disabled
                onChange={() => {}}
              />

              <Autocomplete
                label={translate(
                  'loanApplication.statusCard.summaryModal.salesOrganization'
                )}
                options={organizationsOptions}
                value={
                  selectedOrganization
                    ? {
                        ...selectedOrganization,
                        label: selectedOrganization.value,
                      }
                    : ''
                }
                onChange={(e, selected) => {
                  setSelectedOrganization(selected)
                }}
                error={errors.salesOrganizationId}
                disabled={areInputsDisabled}
              />
            </div>
          </Card>
        ) : null}

        <Card sx={{ mt: '24px' }}>
          <div className="applicant-info__form-title">
            {translate('loanApplication.statusCard.summaryModal.general')}
          </div>

          <div className={styles.generalCard}>
            <div>
              <div className={styles.label}>
                {' '}
                {translate('loanApplication.statusCard.summaryModal.status')}
              </div>
              <StatusComponent
                status={LOAN_STATUS_ID_MAPPING[loanData.loanStatus?.id]}
                label={translate(
                  `loanApplication.statusCard.loanApplicationStatus.${loanData.loanStatus?.id}`
                )}
              />
            </div>
            <div>
              <div className={styles.label}>
                {translate(
                  'loanApplication.statusCard.summaryModal.applicationNumber'
                )}
              </div>
              {loanData.loanApplicationNumber}
            </div>
            <div>
              <div className={styles.label}>
                {translate('loanApplication.statusCard.summaryModal.autopay')}
              </div>
              {achStatusText}
            </div>
            <div>
              <div className={styles.label}>
                {translate(
                  'loanApplication.statusCard.summaryModal.loanAmount'
                )}
              </div>{' '}
              {formatIntToCurrency(loanData.loanAmount)}
            </div>
            <div>
              <div className={styles.label}>
                {translate(
                  'loanApplication.statusCard.summaryModal.loanProduct'
                )}
              </div>
              {loanData.loanName}
            </div>
          </div>
        </Card>
      </div>
    </Dialog>
  )
}

LoanSummary.propTypes = {
  isCommercialApp: PropTypes.bool,
  borrowerDetails: PropTypes.object,
  coBorrowerDetails: PropTypes.object,
  serviceAddress: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  loanData: PropTypes.object.isRequired,
  setIsSummaryModalOpen: PropTypes.func.isRequired,
  areInputsDisabled: PropTypes.bool,
  isSummaryModalOpen: PropTypes.bool,
  achStatus: PropTypes.number,
}

export default LoanSummary
