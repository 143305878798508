import { useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'

import TextField, { INPUT_TYPES } from 'components/input'
import { GENERAL_ADDRESS_ERRORS } from 'common/constants'

import {
  setBuildingField,
  setErrors,
  useCommercialAppContext,
} from '../../store'
import { AggregatedAddress } from '../aggregated-address'
import { BUILDING_TYPES } from '../../store/constants'

const { Paper, Dropdown } = components

export const Building = () => {
  const {
    state: {
      building: {
        isLease = false,
        // own
        lenderName,
        lenderAddress,
        lenderPhoneNumber,
        estimatedPropertyValue,
        monthlyMortgagePayment,
        remainingLoanBalance,
        // lease
        landlordName,
        landlordAddress,
        landlordPhoneNumber,
        monthlyRent,
        monthsRemainingOnLease,
      },
      errors,
    },
    dispatch,
  } = useCommercialAppContext()
  const { t: translate } = useTranslation()

  const [
    displayExpandedLandLordAddressFields,
    setDisplayExpandedLandLordAddressFields,
  ] = useState(false)
  const [
    displayExpandedLenderAddressFields,
    setDisplayExpandedLenderAddressFields,
  ] = useState(false)

  const handleSetSelectedBuildingType = (value) => {
    setBuildingField(dispatch, 'isLease', !!value)
  }

  const handleOnChange = (value, key) => {
    setBuildingField(dispatch, key, value)
  }

  const onAddressSelected = (objKey) => (addressObj) => {
    if (addressObj) {
      if ([objKey].addressId) {
        addressObj.addressId = [objKey].addressId
      }
      setBuildingField(dispatch, objKey, addressObj)
    }
    setErrors(dispatch, { ...GENERAL_ADDRESS_ERRORS }, 'building', objKey)
  }

  const hasLenderAddrErrors = useMemo(
    () => Object.values(errors.building.lenderAddress || {}).some((el) => !!el),
    [errors.building?.lenderAddress]
  )

  const hasLandLordAddrErrors = useMemo(
    () =>
      Object.values(errors.building.landlordAddress || {}).some((el) => !!el),
    [errors.building?.landlordAddress]
  )

  const renderOwnForm = () => (
    <>
      <TextField
        label={translate('loanApplication.commercial.lenderName')}
        validate={() => errors.building.lenderName}
        value={lenderName || ''}
        onChange={(val) => handleOnChange(val, 'lenderName')}
        className={'grid-item-full-width'}
      />

      <div className={cx('grid-item-full-width')}>
        <AggregatedAddress
          address={lenderAddress}
          label={translate('loanApplication.commercial.lenderAddress')}
          errorObjectName={'lenderAddress'}
          errors={errors.building}
          hasErrors={hasLenderAddrErrors}
          displayExpandedAddressFields={displayExpandedLenderAddressFields}
          handleSetAddress={(addressDetails) =>
            setBuildingField(dispatch, 'lenderAddress', addressDetails)
          }
          onAddressSelected={onAddressSelected('lenderAddress')}
          toggleDisplayExpandedAddressFields={() =>
            setDisplayExpandedLenderAddressFields(
              !displayExpandedLenderAddressFields
            )
          }
          hideBypassValidation={true}
          setErrors={(errs) => {
            setErrors(dispatch, errs, 'building')
          }}
        />
      </div>

      <TextField
        label={translate('loanApplication.commercial.lenderPhoneNumber')}
        validate={() => errors.building.lenderPhoneNumber}
        type={INPUT_TYPES.PHONE}
        value={lenderPhoneNumber || ''}
        onChange={(val) => handleOnChange(val, 'lenderPhoneNumber')}
      />
      <TextField
        label={translate('loanApplication.commercial.estimatedPropertyValue')}
        validate={() => errors.building.estimatedPropertyValue}
        type={INPUT_TYPES.CURRENCY}
        prefix={'$'}
        value={estimatedPropertyValue || ''}
        onChange={(val) => handleOnChange(val, 'estimatedPropertyValue')}
      />
      <TextField
        label={translate('loanApplication.commercial.monthlyMortgagePayment')}
        validate={() => errors.building.monthlyMortgagePayment}
        type={INPUT_TYPES.CURRENCY}
        prefix={'$'}
        value={monthlyMortgagePayment || ''}
        onChange={(val) => handleOnChange(val, 'monthlyMortgagePayment')}
      />
      <TextField
        label={translate('loanApplication.commercial.remainingLoanBalance')}
        type={INPUT_TYPES.CURRENCY}
        prefix={'$'}
        validate={() => errors.building.remainingLoanBalance}
        value={remainingLoanBalance || ''}
        onChange={(val) => handleOnChange(val, 'remainingLoanBalance')}
      />
    </>
  )

  const renderLeaseForm = () => (
    <>
      <TextField
        label={translate('loanApplication.commercial.landLordName')}
        validate={() => errors.building.landlordName}
        value={landlordName || ''}
        onChange={(val) => handleOnChange(val, 'landlordName')}
        className={'grid-item-full-width'}
      />{' '}
      <div className={'grid-item-full-width'}>
        <AggregatedAddress
          address={landlordAddress}
          label={translate('loanApplication.commercial.landLordAddress')}
          errorObjectName={'landlordAddress'}
          errors={errors.building}
          hasErrors={hasLandLordAddrErrors}
          displayExpandedAddressFields={displayExpandedLandLordAddressFields}
          handleSetAddress={(addressDetails) =>
            setBuildingField(dispatch, 'landlordAddress', addressDetails)
          }
          onAddressSelected={onAddressSelected('landlordAddress')}
          toggleDisplayExpandedAddressFields={() =>
            setDisplayExpandedLandLordAddressFields(
              !displayExpandedLandLordAddressFields
            )
          }
          hideBypassValidation={true}
          setErrors={(errs) => {
            setErrors(dispatch, errs, 'building')
          }}
          dispatch={dispatch}
        />
      </div>
      <TextField
        label={translate('loanApplication.commercial.landLordPhoneNumber')}
        validate={() => errors.building.landlordPhoneNumber}
        type={INPUT_TYPES.PHONE}
        value={landlordPhoneNumber || ''}
        onChange={(val) => handleOnChange(val, 'landlordPhoneNumber')}
      />
      <TextField
        label={translate('loanApplication.commercial.monthlyRent')}
        validate={() => errors.building.monthlyRent}
        type={INPUT_TYPES.CURRENCY}
        prefix={'$'}
        value={monthlyRent || ''}
        onChange={(val) => handleOnChange(val, 'monthlyRent')}
      />
      <TextField
        label={translate('loanApplication.commercial.monthsRemainingOnLease')}
        validate={() => errors.building.monthsRemainingOnLease}
        type={INPUT_TYPES.NUMBER}
        value={monthsRemainingOnLease || ''}
        onChange={(val) => handleOnChange(val, 'monthsRemainingOnLease')}
      />
    </>
  )

  return (
    <Paper className="paper">
      <div className="loan-form__grid">
        <div className="grid-item-full-width">
          <span className="applicant-info__form-title">
            {translate('loanApplication.commercial.building')}
          </span>
        </div>

        <Dropdown
          options={BUILDING_TYPES}
          onChange={(e) => handleSetSelectedBuildingType(e.target.value.id)}
          value={BUILDING_TYPES[isLease ? 1 : 0]}
          className={'grid-item-full-width'}
        />
        {isLease ? renderLeaseForm() : renderOwnForm()}
      </div>
    </Paper>
  )
}
