import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import TextField from 'components/input'
import Modal from 'components/modal'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { useMediaQuery } from 'common/hooks'
import { LOAN_PRODUCT_STATUSES, TABLET_BREAKPOINT } from 'common/constants'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Checkbox from 'components/checkbox'
import { useStore } from 'store'

import { STATES_ORDER, STATES_ORDER_COMMERCIAL } from '../constants'
import { acceptAttestationVerbiage, enhanceNTP } from '../actions'
import {
  areFundsRequested,
  areNtpSubmitted,
  areNtpsApproved,
  areFundsGranted,
} from './helpers'

import { styles } from './style'

const eniumPhoneNumber = process.env.REACT_APP_ENIUM_PHONE_NO

const {
  SendIcon,
  CheckCircleBrokenIcon,
  FileCheck01Icon,
  ChevronDownIcon,
  PlusSquareIcon,
  CheckVerified02Icon,
} = assets
const { Dialog, Dropdown } = components

const NTPButtons = ({
  isInitial,
  currentState,
  submitNTPForApproval,
  approveNTP,
  requestFunds,
  grantFunds,
  canSubmitForApproval,
  canApprove,
  loanAmount,
  loanStatusId,
  initialFundingAmount,
  setAddStipulationOpen,
  isCommercial,
  representativeName,
  loanApplicationId,
  isEnhanced,
  isInCorrespondingNtpStep,
  setIsEnhanced,
  ntpSectionId,
}) => {
  const { state } = useStore()
  const { userData } = state.session
  const [showContactEniumModal, setShowContactEniumModal] = useState(false)
  const [showAttestationModal, setShowAttestationModal] = useState(false)
  const [stepTranslationKey, setStepTranslationKey] = useState('')
  const [ntpSubmitted, setNtpSubmitted] = useState()
  const [ntpApproved, setNtpApproved] = useState()
  const [fundsRequested, setFundsRequested] = useState()
  const [fundsGranted, setFundsGranted] = useState()
  const [enhancedLoading, setEnhancedLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t: translate } = useTranslation()
  const isTabletView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  useEffect(() => {
    const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER
    const stepTranslationKey = isCommercial
      ? 'step4'
      : isInitial
      ? 'step5'
      : 'step6'
    const state = statesOrder[currentState]
    const ntpSubmitted = areNtpSubmitted(state, isInitial, isCommercial)
    const ntpApproved = areNtpsApproved(state, isInitial, isCommercial)
    const fundsRequested = areFundsRequested(state, isInitial, isCommercial)
    const fundsGranted = areFundsGranted(state, isInitial, isCommercial)

    setStepTranslationKey(stepTranslationKey)
    setNtpSubmitted(ntpSubmitted)
    setNtpApproved(ntpApproved)
    setFundsRequested(fundsRequested)
    setFundsGranted(fundsGranted)
  }, [isCommercial, isInitial, currentState])

  const [showGrantModal, setGrandModal] = useState(false)
  const [initialFunds, setInitialFunds] = useState(initialFundingAmount || '0')
  const [initialError, setInitialError] = useState(null)

  /** On Submit Funds clicked */
  const submitFunds = () => {
    // For Initial NTP also validate
    if (isInitial) {
      if (!initialFunds) {
        return setInitialError(
          translate('loanApplication.step5.errors.fundingAmountRequired')
        )
      }
      if (initialFunds > loanAmount) {
        return setInitialError(
          translate('loanApplication.step5.errors.totalAmountExceded')
        )
      }
    }
    setLoading(true)
    grantFunds(initialFunds).finally(() => {
      setLoading(false)
      setGrandModal(false)
    })
  }

  const handleRequestFunds = () => {
    if (
      loanStatusId === LOAN_PRODUCT_STATUSES.CANCELED ||
      loanStatusId === LOAN_PRODUCT_STATUSES.ON_HOLD
    ) {
      setShowContactEniumModal(true)
      return
    }

    if (!isCommercial && !isInitial) {
      setShowAttestationModal(true)
    } else {
      setLoading(true)
      requestFunds().finally(() => setLoading(false))
    }
  }

  const handleAgreeAttestation = async () => {
    setLoading(true)
    setShowAttestationModal(false)
    acceptAttestationVerbiage(loanApplicationId)
      .then(() => {
        requestFunds().finally(() => setLoading(false))
      })
      .catch(() => setLoading(false))
  }

  const _buildActions = () => {
    const actionButtons = []
    const actionDropdownOptions = []
    if (isInitial && hasAccess(userData, CLAIMS.CAN_USE_ENHANCED_IF)) {
      const button = (
        <Checkbox
          size={'medium'}
          style={{ paddingTop: 0, paddingBottom: 0, marginLeft: '2px' }}
          checked={isEnhanced}
          disabled={ntpApproved || !isInCorrespondingNtpStep || enhancedLoading}
          onClick={() => {
            setEnhancedLoading(true)
            enhanceNTP(ntpSectionId, !isEnhanced)
              .then(() => setIsEnhanced(!isEnhanced))
              .finally(() => setEnhancedLoading(false))
          }}
          label={translate('loanApplication.step5.enhancedFunding')}
        />
      )
      actionButtons.push(button)
      actionDropdownOptions.push({
        id: 'enhanced',
        key: 0,
        disabled: ntpApproved || !isInCorrespondingNtpStep,
        label: button,
      })
    }

    if (!isCommercial) {
      const disabled = loading || !canSubmitForApproval || ntpSubmitted
      const label = !ntpSubmitted
        ? translate('loanApplication.ntpStepsCommon.submitForApproval')
        : translate('loanApplication.ntpStepsCommon.alreadySubmitted')
      actionButtons.push(
        <Button
          disabled={disabled}
          loading={loading}
          onClick={() => {
            setLoading(true)
            submitNTPForApproval().finally(() => setLoading(false))
          }}
          startIcon={<FileCheck01Icon />}
        >
          {label}
        </Button>
      )
      actionDropdownOptions.push({
        id: 'submitNTPForApproval',
        key: 1,
        disabled,
        label: (
          <div style={styles.ntpActions}>
            <FileCheck01Icon sx={{ mr: 1, width: 20, height: 20 }} />
            {label}
          </div>
        ),
      })
    }

    if (hasAccess(userData, CLAIMS.CAN_APPROVE_NTPS)) {
      const disabled = loading || !ntpSubmitted || ntpApproved || !canApprove
      const label = translate(
        `loanApplication.${stepTranslationKey}.approveStipulation`
      )
      actionButtons.push(
        <Button
          onClick={() => {
            setLoading(true)
            approveNTP().finally(() => setLoading(false))
          }}
          disabled={disabled}
          loading={loading}
          startIcon={<SendIcon />}
        >
          {label}
        </Button>
      )
      actionDropdownOptions.push({
        id: 'approveNTP',
        key: 2,
        disabled,
        label: (
          <div style={styles.ntpActions}>
            <SendIcon sx={{ mr: 1, width: 20, height: 20 }} />
            {label}
          </div>
        ),
      })
    }

    if (!isCommercial) {
      const label = translate(
        `loanApplication.${stepTranslationKey}.requestFunds`
      )
      const disabled = loading || !ntpApproved || fundsRequested
      actionButtons.push(
        <Button
          disabled={disabled}
          loading={loading}
          onClick={() => {
            handleRequestFunds()
          }}
          startIcon={<CheckCircleBrokenIcon />}
        >
          {label}
        </Button>
      )
      actionDropdownOptions.push({
        id: 'requestFunds',
        key: 3,
        label: (
          <div style={styles.ntpActions}>
            <CheckCircleBrokenIcon sx={{ mr: 1, width: 20, height: 20 }} />
            {label}
          </div>
        ),
        disabled,
      })
    }

    if (!isCommercial && hasAccess(userData, CLAIMS.CAN_GRANT_FUNDS)) {
      const disabled = loading || !fundsRequested || fundsGranted
      const label = translate(
        `loanApplication.${stepTranslationKey}.grantFunds`
      )
      actionButtons.push(
        <Button
          disabled={disabled}
          loading={loading}
          onClick={() => {
            setGrandModal(true)
          }}
          startIcon={<CheckVerified02Icon />}
        >
          {label}
        </Button>
      )
      actionDropdownOptions.push({
        id: 'grantFunds',
        key: 4,
        label: (
          <div style={styles.ntpActions}>
            <CheckVerified02Icon sx={{ mr: 1, width: 20, height: 20 }} />
            {label}
          </div>
        ),
        disabled,
      })
    }

    if (hasAccess(userData, CLAIMS.CAN_ADD_STIPULATIONS)) {
      const disabled = fundsRequested
      const label = translate('loanApplication.ntpStepsCommon.addStipulation')
      actionButtons.push(
        <Button
          onClick={() => {
            !fundsRequested && setAddStipulationOpen(true)
          }}
          disabled={disabled}
          loading={loading}
          startIcon={<PlusSquareIcon />}
        >
          {label}
        </Button>
      )
      actionDropdownOptions.push({
        id: 'addStipulation',
        key: 5,
        label: (
          <div style={styles.ntpActions}>
            <PlusSquareIcon sx={{ mr: 1, width: 20, height: 20 }} />
            {label}
          </div>
        ),
        disabled,
      })
    }

    return { actionButtons, actionDropdownOptions }
  }

  const { actionDropdownOptions, actionButtons } = _buildActions()

  const handleDropdownChange = (ev) => {
    switch (ev.target.value.id) {
      case 'submitNTPForApproval':
        setLoading(true)
        submitNTPForApproval().finally(() => setLoading(false))
        break
      case 'approveNTP':
        setLoading(true)
        approveNTP().finally(() => setLoading(false))
        break
      case 'requestFunds':
        handleRequestFunds()
        break
      case 'grantFunds':
        setGrandModal(true)
        break
      case 'addStipulation':
        !fundsRequested && setAddStipulationOpen(true)
        break
      default:
        break
    }
  }

  return (
    <>
      {isTabletView ? (
        <div style={styles.ntpButtonsMobile}>
          {actionButtons.map((el) => el)}
        </div>
      ) : (
        <div style={styles.ntpButtons}>
          <Dropdown
            options={actionDropdownOptions}
            value={{}}
            customTrigger={
              <Button
                endIcon={<ChevronDownIcon />}
                onClick={() => {}}
                variant={BUTTON_VARIANTS.CONTAINED}
                e
              >
                {translate('Actions')}
              </Button>
            }
            onChange={handleDropdownChange}
          />
        </div>
      )}

      {/* Other modals and dialogs */}
      <Modal
        title=""
        isOpen={showContactEniumModal}
        onClose={() => setShowContactEniumModal(false)}
      >
        <div className="delete-modal__question">
          {translate('loanApplication.step2.contactEnium1')}{' '}
          <a href={`tel:+1-${eniumPhoneNumber}`}>{eniumPhoneNumber}</a>{' '}
          {translate('loanApplication.ntpStepsCommon.contactEniumRequestFunds')}
        </div>
      </Modal>

      <Dialog
        title=""
        open={showAttestationModal}
        onClose={() => setShowAttestationModal(false)}
        confirmButton={translate(`buttons.submit`)}
        actions={
          <>
            <Button
              onClick={() => setShowAttestationModal(false)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button onClick={handleAgreeAttestation}>
              {translate(`buttons.submit`)}
            </Button>
          </>
        }
      >
        <div className="delete-modal__question">
          {translate('loanApplication.step6.attestationModalVerbiage', {
            representativeName,
          })}
        </div>
      </Dialog>
      <Dialog
        title={translate(`loanApplication.${stepTranslationKey}.grantFunds`)}
        open={showGrantModal}
        onClose={() => setGrandModal(false)}
        actions={
          <>
            <Button
              onClick={() => setGrandModal(false)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button onClick={submitFunds} disabled={loading}>
              {translate(`loanApplication.${stepTranslationKey}.grantFunds`)}
            </Button>
          </>
        }
      >
        <TextField
          label={translate(`loanApplication.step5.fundingAmount`)}
          type="currency"
          fixedDecimalScale={true}
          disabled={!isInitial}
          value={initialFunds}
          onChange={(val) => {
            setInitialFunds(val)
            setInitialError(null)
          }}
          max={loanAmount}
          validate={() => initialError}
          style={{ marginRight: '8px' }}
        />
        <TextField
          label={translate(
            `loanApplication.ntpStepsCommon.amountLeftToBeFunded`
          )}
          disabled={true}
          type="currency"
          fixedDecimalScale={true}
          value={loanAmount - initialFunds}
          onChange={() => {}}
        />

        <div>
          <div style={styles.totalLoanAmountLabel}>
            {translate('loanApplication.ntpStepsCommon.totalLoanAmount')}
          </div>
          <div>{`$ ${loanAmount}`}</div>
        </div>
      </Dialog>
    </>
  )
}

NTPButtons.propTypes = {
  isInitial: PropTypes.bool.isRequired,
  currentState: PropTypes.string.isRequired,
  submitNTPForApproval: PropTypes.func.isRequired,
  approveNTP: PropTypes.func.isRequired,
  requestFunds: PropTypes.func.isRequired,
  grantFunds: PropTypes.func.isRequired,
  canSubmitForApproval: PropTypes.bool.isRequired,
  canApprove: PropTypes.bool.isRequired,
  loanAmount: PropTypes.number.isRequired,
  initialFundingAmount: PropTypes.number,
  setAddStipulationOpen: PropTypes.func.isRequired,
  loanStatusId: PropTypes.number.isRequired,
  isCommercial: PropTypes.bool,
  representativeName: PropTypes.string,
  loanApplicationId: PropTypes.string.isRequired,
  ntpApproved: PropTypes.bool,
  isInCorrespondingNtpStep: PropTypes.bool,
  ntpSectionId: PropTypes.string,
  isEnhanced: PropTypes.bool,
  setIsEnhanced: PropTypes.func.isRequired,
}

export default NTPButtons
