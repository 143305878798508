import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TwoFactorReminder } from './reminder'
import { TwoFactorPhoneSetup } from './phone-set-up'
import { TwoFactorPinSetup } from './pin-set-up'
import { Congratulations2FA } from './success'

export const SetUpTwoFactor = ({
  setIsTwoFactorAuthOpen,
  emailAddress,
  password,
  currentPhoneNumber,
  is2FAEnabled,
}) => {
  const [isReminder, setIsReminder] = useState(false)
  const [isPhoneSetup, setIsPhoneSetup] = useState(false)
  const [isPinSetup, setIsPinSetup] = useState(false)
  const [isCongratulations, setIsCongratulations] = useState(false)
  const [changePhoneNumberToken, setChangePhoneNumberToken] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState('')

  useEffect(() => {
    if (is2FAEnabled) {
      setIsPinSetup(true)
    } else {
      setIsReminder(true)
    }
  }, [is2FAEnabled])

  return (
    <>
      {isReminder && (
        <TwoFactorReminder
          setIsPhoneSetup={setIsPhoneSetup}
          setIsReminder={setIsReminder}
        />
      )}
      {isPhoneSetup && (
        <TwoFactorPhoneSetup
          setIsPinSetup={setIsPinSetup}
          setIsPhoneSetup={setIsPhoneSetup}
          emailAddress={emailAddress}
          password={password}
          setChangePhoneNumberToken={setChangePhoneNumberToken}
          newPhoneNumber={newPhoneNumber}
          currentPhoneNumber={currentPhoneNumber}
          changePhoneNumberToken={changePhoneNumberToken}
          setNewPhoneNumber={setNewPhoneNumber}
        />
      )}
      {isPinSetup && (
        <TwoFactorPinSetup
          onClose={() => {
            setIsPinSetup(false)
            setIsTwoFactorAuthOpen(false)
          }}
          setChangePhoneNumberToken={setChangePhoneNumberToken}
          setIsPinSetup={setIsPinSetup}
          emailAddress={emailAddress}
          changePhoneNumberToken={changePhoneNumberToken}
          newPhoneNumber={newPhoneNumber}
          currentPhoneNumber={currentPhoneNumber}
          setIsCongratulations={setIsCongratulations}
          password={password}
        />
      )}

      {isCongratulations && <Congratulations2FA />}
    </>
  )
}

SetUpTwoFactor.propTypes = {
  setIsTwoFactorAuthOpen: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  currentPhoneNumber: PropTypes.string,
  is2FAEnabled: PropTypes.bool,
}
