import { SERVICE_ADDRESS_ERROR_MAP } from 'common/constants'
import { ERRORS, validate } from 'components/validator'
import {
  BUILDING_LEASE_SECTION_ERROR_MAP,
  BUILDING_OWN_SECTION_ERROR_MAP,
  BUSINESS_OWNER_SECTION_ERROR_MAP,
  DEFAULT_BUSINESS_OWNER_ERRORS,
  INFO_SECTION_ERROR_MAP,
} from './store/constants'

export const validateCommercialApp = (loanApp, hasOrgsAccess, translate) => {
  let isValid = true
  let infoSectionErrors = {}
  let buildingSectionErrors = {}
  const businessOwnersSection = []

  // Validate rest of info section
  const [isValidInfoSection, infoSectionPartialErrors] = validate(
    INFO_SECTION_ERROR_MAP,
    { ...loanApp.loanInfo }
  )

  if (!isValidInfoSection) {
    infoSectionErrors = infoSectionPartialErrors
    isValid = false
  }

  // Validate Organization
  if (!loanApp.organization && hasOrgsAccess) {
    isValid = false
    infoSectionErrors.organization = ERRORS.REQUIRED
  }

  // Validate Address in the info section
  const [isServiceAddressValid, serviceAddressErrors] = validate(
    SERVICE_ADDRESS_ERROR_MAP,
    { ...loanApp.loanInfo.serviceAddress }
  )

  if (!isServiceAddressValid) {
    infoSectionErrors.serviceAddress = serviceAddressErrors
    isValid = false
  }

  const [isMailingAddressValid, mailingAddressErrors] = validate(
    SERVICE_ADDRESS_ERROR_MAP,
    { ...loanApp.loanInfo.mailingAddress }
  )

  if (!isMailingAddressValid) {
    infoSectionErrors.mailingAddress = mailingAddressErrors
    isValid = false
  }

  // Validate building section
  const isLease = loanApp.building.isLease
  const [isValidBuildingSection, buldingSectionErrors] = validate(
    isLease ? BUILDING_LEASE_SECTION_ERROR_MAP : BUILDING_OWN_SECTION_ERROR_MAP,
    { ...loanApp.building }
  )

  if (!isValidBuildingSection) {
    buildingSectionErrors = buldingSectionErrors
    isValid = false
  }

  if (!isLease) {
    const [isLenderAddressValid, lenderAddressErrors] = validate(
      SERVICE_ADDRESS_ERROR_MAP,
      { ...loanApp.building.lenderAddress }
    )
    if (!isLenderAddressValid) {
      buildingSectionErrors.lenderAddress = lenderAddressErrors
      isValid = false
    }
  }

  if (isLease) {
    const [isLandLordAddressValid, landlordAddressErrors] = validate(
      SERVICE_ADDRESS_ERROR_MAP,
      { ...loanApp.building.landlordAddress }
    )
    if (!isLandLordAddressValid) {
      buildingSectionErrors.landlordAddress = landlordAddressErrors
      isValid = false
    }
  }

  // Validate business owners section
  loanApp.businessOwners.forEach((businessOwner) => {
    const [isValidBusinessSection, businessSectionErrors] = validate(
      BUSINESS_OWNER_SECTION_ERROR_MAP,
      { ...businessOwner }
    )

    // Validate Address in the business owner section
    const [isValidBusinessOwnerHomeAddress, businessOwnerHomeAddressErrors] =
      validate(SERVICE_ADDRESS_ERROR_MAP, { ...businessOwner.homeAddress })

    if (!isValidBusinessOwnerHomeAddress) {
      businessSectionErrors.homeAddress = businessOwnerHomeAddressErrors
      isValid = false
    }

    // Validate ownership percentage
    let isValidOwnership = true
    if (
      !businessSectionErrors.ownership &&
      (businessOwner.ownership < 20 || businessOwner.ownership > 100)
    ) {
      businessSectionErrors.ownership = translate(
        'loanApplication.commercial.errors.ownership'
      )
      isValidOwnership = false
    }

    if (
      !isValidBusinessSection ||
      !isValidBusinessOwnerHomeAddress ||
      !isValidOwnership
    ) {
      businessOwnersSection.push(businessSectionErrors)
      isValid = false
    } else {
      businessOwnersSection.push(DEFAULT_BUSINESS_OWNER_ERRORS)
    }
  })

  return {
    isValid,
    infoSectionErrors,
    buildingSectionErrors,
    businessOwnersSection,
  }
}

export const formatSubmitCommercialAppPayload = (
  appState,
  loanApplicationId,
  disclosureStatuses,
  isAuthAgreed,
  isTCAgreed,
  isElectronicAgreed
) => {
  const buildingDetails = formatBuildingDetailsPayload(appState.building)
  const principals = formatBusinessOwnersPayload(
    appState.businessOwners,
    loanApplicationId
  )
  return {
    loanApplicationId: loanApplicationId,
    salesOrganizationId: appState.organization,
    bypassAddressValidation: appState.bypassValidateAddress,
    ...appState.loanInfo,
    buildingDetails: { ...buildingDetails },
    principals: [...principals],
    selectedLoanSignatures: formatSelectedLoanSignaturesPayload(
      appState.allLoanSignatures,
      appState.loanInfo.loanSignatures
    ),
    disclosureStatuses,
    acknowledgementAndAuthorizationDisclosureAccepted: isAuthAgreed,
    termsAndConditionsDisclosureAccepted: isTCAgreed,
    electronicRecordAndSignatureDisclosureAccepted: isElectronicAgreed,
  }
}

const formatSelectedLoanSignaturesPayload = (
  allLoanSignatures,
  selectedLoanSignatures
) => {
  const noLoanSignaturesSelected = !selectedLoanSignatures.length

  return allLoanSignatures.map((el) => ({
    selectedLoanSignatureId: el.guid,
    loanProductSignature: el.value,
    isSelected: noLoanSignaturesSelected
      ? true
      : !!selectedLoanSignatures.find((selected) => selected.guid === el.guid),
  }))
}

const formatBuildingDetailsPayload = (building) => {
  const buildingDetails = {
    buildingDetailsId: building.buildingDetailsId,
    buildingTypeId: building.isLease ? 1 : 0,
  }

  if (!building.isLease) {
    buildingDetails.ownBuildingPropertyDetails = {
      lenderName: building.lenderName,
      lenderPhoneNumber: building.lenderPhoneNumber,
      estimatedPropertyValue: building.estimatedPropertyValue,
      monthlyMortgagePayment: building.monthlyMortgagePayment,
      remainingLoanBalance: building.remainingLoanBalance,
      lenderAddress: {
        ...building.lenderAddress,
      },
    }
  } else {
    buildingDetails.leaseBuildingPropertyDetails = {
      landlordName: building.landlordName,
      landlordPhoneNumber: building.landlordPhoneNumber,
      monthlyRent: building.monthlyRent,
      monthsRemainingOnLease: building.monthsRemainingOnLease,
      landlordAddress: {
        ...building.landlordAddress,
      },
    }
  }
  return buildingDetails
}

const formatBusinessOwnersPayload = (businessOwners, loanApplicationId) => {
  return businessOwners.map((owner) => {
    return {
      principalId: owner.principalId,
      loanApplicationId: loanApplicationId,
      firstName: owner.firstName,
      lastName: owner.lastName,
      last4SSN: owner.last4SSN,
      annualGrossIncome: owner.annualGrossIncome,
      ownershipPercentage: owner.ownership,
      dateOfBirth: owner.dateOfBirth,
      homeAddress: { ...owner.homeAddress },
    }
  })
}

export const formatCommercialAppServerDataToState = (serverLoanApp) => {
  return {
    organization: serverLoanApp.salesOrganizationId,
    bypassValidateAddress: serverLoanApp.bypassAddressValidation,
    allLoanSignatures: [...serverLoanApp.selectedLoanSignatures],
    loanInfo: serverDataToLoanInfo(serverLoanApp),
    building: serverDataToBuilding(serverLoanApp.buildingDetails),
    businessOwners: serverDataToBusinessOwners(serverLoanApp.businessOwners),
  }
}

const serverDataToLoanInfo = (serverLoanApp) => {
  return {
    legalCompanyName: serverLoanApp.legalCompanyName,
    businessTypeId: serverLoanApp.businessType.id,
    einNumber: serverLoanApp.einNumber,
    businessStartDate: serverLoanApp.businessStartDate,
    annualGrossRevenue: serverLoanApp.annualGrossRevenue,
    mailingAddress: { ...serverLoanApp.mailingAddress },
    businessPhone: serverLoanApp.businessPhone,
    mobilePhone: serverLoanApp.mobilePhone,
    emailAddress: serverLoanApp.emailAddress,
    natureOfBusiness: serverLoanApp.natureOfBusiness,
    debtServiceSchedule: serverLoanApp.debtServiceSchedule,
    // top section
    loanSignatures: [...serverLoanApp.selectedLoanSignatures].filter(
      (el) => el.isSelected
    ),
    loanAmount: serverLoanApp.loanAmount,
    serviceAddress: serverLoanApp.serviceAddress,
  }
}

const serverDataToBuilding = (buildingDetails) => {
  const isLease = buildingDetails.buildingTypeId === 1
  const own = buildingDetails.ownBuildingPropertyDetails
  const lease = buildingDetails.leaseBuildingPropertyDetails
  return {
    buildingDetailsId: buildingDetails.buildingDetailsId,
    isLease,
    // own
    ...(!isLease && {
      lenderName: own.lenderName,
      lenderAddress: { ...own.lenderAddress },
      lenderPhoneNumber: own.lenderPhoneNumber,
      estimatedPropertyValue: own.estimatedPropertyValue,
      monthlyMortgagePayment: own.monthlyMortgagePayment,
      remainingLoanBalance: own.remainingLoanBalance,
    }),
    // lease
    ...(isLease && {
      landlordName: lease.landlordName,
      landlordAddress: { ...lease.landlordAddress },
      landlordPhoneNumber: lease.landlordPhoneNumber,
      monthlyRent: lease.monthlyRent,
      monthsRemainingOnLease: lease.monthsRemainingOnLease,
    }),
  }
}

const serverDataToBusinessOwners = (principals) => {
  return principals.map((principal) => ({
    principalId: principal.principalId,
    loanApplicationId: principal.loanApplicationId,
    firstName: principal.firstName,
    lastName: principal.lastName,
    ownership: principal.ownershipPercentage,
    dateOfBirth: principal.dateOfBirth,
    last4SSN: principal.last4SSN,
    annualGrossIncome: principal.annualGrossIncome,
    homeAddress: { ...principal.homeAddress },
  }))
}
