import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'

import { DESKTOP_BREAKPOINT, GENERAL_ADDRESS_ERRORS } from 'common/constants'
import DateInput from 'components/input-date'
import TextField, { INPUT_TYPES } from 'components/input'

import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { useMediaQuery } from 'common/hooks'

import {
  setBusinessOwnerField,
  addBusinessOwner,
  removeBusinessOwner,
  useCommercialAppContext,
  setErrors,
} from '../../store'
import { AggregatedAddress } from '../aggregated-address'

const { Paper, Button, Tooltip, IconButton } = components
const { PlusIcon, TrashIcon } = assets

export const BusinessOwner = ({ isEdit, canSeeSensitiveData }) => {
  const {
    state: { businessOwners, errors, areInputsDisabled },
    dispatch,
  } = useCommercialAppContext()
  const { t: translate } = useTranslation()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const [displayExpandedAddressFields, setDisplayExpandedAddressFields] =
    useState(new Array(businessOwners.length).fill(false))

  const handleOnChange = (key, id) => (value) => {
    setBusinessOwnerField(dispatch, key, value, id)
  }

  const renderOwnerForm = (owner, id) => (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Remove owner">
          <IconButton
            variant="text"
            color="error"
            disabled={businessOwners.length < 2}
            onClick={() => {
              removeBusinessOwner(dispatch, id)
              displayExpandedAddressFields.splice(id, 1)
            }}
          >
            <TrashIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className="loan-form__grid">
        <TextField
          label={translate('userDetails.firstName')}
          validate={() => errors.businessOwners[id].firstName}
          value={owner.firstName || ''}
          onChange={handleOnChange('firstName', id)}
          disabled={areInputsDisabled}
        />
        <TextField
          label={translate('userDetails.lastName')}
          validate={() => errors.businessOwners[id].lastName}
          value={owner.lastName || ''}
          onChange={handleOnChange('lastName', id)}
          disabled={areInputsDisabled}
        />
        <TextField
          label={translate('loanApplication.commercial.ownership')}
          type={INPUT_TYPES.PERCENTAGE}
          validate={() => errors.businessOwners[id].ownership}
          value={owner.ownership || ''}
          onChange={handleOnChange('ownership', id)}
          disabled={areInputsDisabled}
        />

        <DateInput
          value={owner.dateOfBirth}
          onChange={handleOnChange('dateOfBirth', id)}
          label={translate('loanApplication.commercial.dateOfBirth')}
          validate={() => errors.businessOwners[id].dateOfBirth}
          hideSecureValue={!canSeeSensitiveData}
          disabled={(isEdit && !canSeeSensitiveData) || areInputsDisabled}
          maxDate={new Date()}
        />

        <TextField
          label={translate(isEdit ? 'userDetails.ssn' : 'userDetails.last4ssn')}
          type={INPUT_TYPES.SENSITIVE}
          validate={() => errors.businessOwners[id].last4SSN}
          value={owner.last4SSN || ''}
          onChange={handleOnChange('last4SSN', id)}
          hideSecureValue={!canSeeSensitiveData}
          disabled={(isEdit && !canSeeSensitiveData) || areInputsDisabled}
        />
        <TextField
          label={translate('loanApplication.commercial.annualGrossIncome')}
          type={INPUT_TYPES.CURRENCY}
          prefix={'$'}
          validate={() => errors.businessOwners[id].annualGrossIncome}
          value={owner.annualGrossIncome || ''}
          onChange={handleOnChange('annualGrossIncome', id)}
          disabled={areInputsDisabled}
        />

        <div className={'grid-item-full-width'}>
          <AggregatedAddress
            address={owner.homeAddress}
            label={translate('loanApplication.commercial.homeAddress')}
            errorObjectName={'homeAddress'}
            errors={errors.businessOwners[id]}
            hasErrors={hasHomeAddressErrors(
              errors.businessOwners[id].homeAddress
            )}
            displayExpandedAddressFields={displayExpandedAddressFields[id]}
            handleSetAddress={(addressDetails) =>
              handleSetHomeAddress(id)(addressDetails)
            }
            onAddressSelected={onAddressSelected(id, owner)}
            toggleDisplayExpandedAddressFields={toggleDisplayExpandedAddressFields(
              id
            )}
            hideBypassValidation={true}
            setErrors={(errs) => {
              setErrors(
                dispatch,
                updateOwnersErrors(id, errs.homeAddress),
                'businessOwners'
              )
            }}
            dispatch={dispatch}
          />
        </div>
      </div>
    </>
  )

  const hasHomeAddressErrors = (address) =>
    Object.values(address || {}).some((el) => !!el)

  const toggleDisplayExpandedAddressFields = (id) => () => {
    setDisplayExpandedAddressFields((fields) =>
      fields.map((value, index) => {
        if (index === id) {
          return !value
        }

        return value
      })
    )
  }

  const onAddressSelected = (id, owner) => (addressObj) => {
    if (addressObj) {
      if (owner.homeAddress.addressId) {
        addressObj.addressId = owner.homeAddress.addressId
      }

      setBusinessOwnerField(dispatch, 'homeAddress', addressObj, id)
    }

    setErrors(
      dispatch,
      updateOwnersErrors(id, GENERAL_ADDRESS_ERRORS),
      'businessOwners'
    )
  }

  const handleSetHomeAddress = (id) => (addressDetails) => {
    setBusinessOwnerField(dispatch, 'homeAddress', addressDetails, id)
  }

  const updateOwnersErrors = (ownerId, errorObject) =>
    errors.businessOwners.map((businessOwnerError, index) => {
      if (ownerId === index) {
        return {
          ...businessOwnerError,
          homeAddress: { ...errorObject },
        }
      }
      return businessOwnerError
    })

  return (
    <Paper className="paper">
      <div className="applicant-info__header">
        <div>
          <span
            className="applicant-info__form-title"
            style={{ paddingLeft: 0 }}
          >
            {translate('loanApplication.commercial.businessOwner')}
          </span>
          <div style={{ marginTop: '10px' }}>
            {translate('loanApplication.commercial.businessOwnerDescription')}
          </div>
        </div>

        <Button
          startIcon={<PlusIcon />}
          disabled={businessOwners.length > 4}
          variant={
            isTabletView ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.TEXT
          }
          color={isTabletView ? BUTTON_COLORS.SECONDARY : BUTTON_COLORS.PRIMARY}
          onClick={() => {
            addBusinessOwner(dispatch)
            displayExpandedAddressFields.push(false)
          }}
        >
          {translate('loanApplication.commercial.addOwner')}
        </Button>
      </div>

      {businessOwners.map((owner, index) => (
        <div
          className={'applicant-info--even'}
          style={{ borderRadius: '8px' }}
          key={index}
        >
          {renderOwnerForm(owner, index)}
        </div>
      ))}
    </Paper>
  )
}

BusinessOwner.propTypes = {
  isEdit: PropTypes.bool,
  canSeeSensitiveData: PropTypes.bool,
}
