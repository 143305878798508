import moment from 'moment'
import { copyToClipboard, formatAddress, formatPhoneNumber } from 'common/utils'
import { formatInUSFormat } from 'common/number'
import { dateFormatUS } from 'common/constants'
import { BUILDING_TYPES } from './store/constants'

export function copyCommercialAppToClipboard({
  dispatch,
  translate,
  state,
  canSeeSensitiveData,
}) {
  const { loanInfo, building } = state
  const { isLease } = building

  let htmlText = `
  <strong>${translate(
    'loanApplication.step1.loanProducts'
  )}</strong>: ${loanInfo.loanSignatures.map((el) => el.value).join(', ')}<br/>
  <strong>${translate(
    'loanApplication.step1.loanAmount'
  )}</strong>: $${formatInUSFormat(loanInfo.loanAmount)}<br/>
  <strong>${translate(
    'loanApplication.step1.address.serviceAddress'
  )}</strong>: ${formatAddress(loanInfo.serviceAddress)}<br/>
  <br/>
  <strong>${translate(
    'loanApplication.commercial.legalCompanyName'
  )}</strong>: ${loanInfo.legalCompanyName}<br/>
  <strong>${translate('loanApplication.commercial.typeOfBusiness')}</strong>: ${
    state.typesOfBusiness.find((el) => el.id === loanInfo.businessTypeId)
      ?.friendlyName
  }<br/>
  <strong>${translate('loanApplication.commercial.einNumber')}</strong>: ${
    loanInfo.einNumber
  }<br/>
  <strong>${translate(
    'loanApplication.commercial.businessStartDate'
  )}</strong>: ${moment(loanInfo.businessStartDate).format(dateFormatUS)}<br/>
  <strong>${translate(
    'loanApplication.commercial.annualGrossRevenue'
  )}</strong>: $${formatInUSFormat(loanInfo.annualGrossRevenue)}<br/>
  <strong>${translate(
    'loanApplication.commercial.mailingAddress'
  )}</strong>: ${formatAddress(loanInfo.mailingAddress)}<br/>
  <strong>${translate(
    'loanApplication.commercial.businessPhone'
  )}</strong>: ${formatPhoneNumber(loanInfo.businessPhone)}<br/>
  <strong>${translate(
    'loanApplication.commercial.mobilePhone'
  )}</strong>: ${formatPhoneNumber(loanInfo.mobilePhone)}<br/>
  <strong>${translate('loanApplication.commercial.emailAddress')}</strong>: ${
    loanInfo.emailAddress
  }<br/>
  <strong>${translate(
    'loanApplication.commercial.natureOfBusiness'
  )}</strong>: ${loanInfo.natureOfBusiness}<br/>
  <strong>${translate(
    'loanApplication.commercial.debtServiceSchedule'
  )}</strong>: ${loanInfo.debtServiceSchedule}<br/><br/>
  <strong>${translate('loanApplication.commercial.building')}:</strong> ${
    BUILDING_TYPES[isLease ? 1 : 0]?.value
  }<br/>
  `
  htmlText += isLease
    ? _leaseForm({ translate, building })
    : _ownForm({ translate, building })

  state.businessOwners.forEach((owner) => {
    htmlText += `<br/><strong>${translate(
      'loanApplication.commercial.businessOwner'
    )}</strong><br/>`
    htmlText += _owner({ translate, owner, canSeeSensitiveData })
  })

  copyToClipboard(htmlText, dispatch)
}

function _ownForm({ translate, building }) {
  return `
  <strong>${translate('loanApplication.commercial.lenderName')}</strong>: ${
    building.lenderName
  }<br/>
  <strong>${translate(
    'loanApplication.commercial.lenderAddress'
  )}</strong>: ${formatAddress(building.lenderAddress)}<br/>
  <strong>${translate(
    'loanApplication.commercial.lenderPhoneNumber'
  )}</strong>: ${formatPhoneNumber(building.lenderPhoneNumber)}<br/>
  <strong>${translate(
    'loanApplication.commercial.estimatedPropertyValue'
  )}</strong>: $${formatInUSFormat(building.estimatedPropertyValue)}<br/>
  <strong>${translate(
    'loanApplication.commercial.monthlyMortgagePayment'
  )}</strong>: $${formatInUSFormat(building.monthlyMortgagePayment)}<br/>
  <strong>${translate(
    'loanApplication.commercial.remainingLoanBalance'
  )}</strong>: $${formatInUSFormat(building.remainingLoanBalance)}<br/>
  `
}

function _leaseForm({ translate, building }) {
  return `
  <strong>${translate('loanApplication.commercial.landLordName')}</strong>: ${
    building.landlordName
  }<br/>
  <strong>${translate(
    'loanApplication.commercial.landLordAddress'
  )}</strong>: ${formatAddress(building.landlordAddress)}<br/>
  <strong>${translate(
    'loanApplication.commercial.landLordPhoneNumber'
  )}</strong>: ${formatPhoneNumber(building.landlordPhoneNumber)}<br/>
  <strong>${translate(
    'loanApplication.commercial.monthlyRent'
  )}</strong>: $${formatInUSFormat(building.monthlyRent)}<br/>
  <strong>${translate(
    'loanApplication.commercial.monthsRemainingOnLease'
  )}</strong>: ${building.monthsRemainingOnLease}<br/>
  `
}

function _owner({ translate, owner, canSeeSensitiveData }) {
  return `
  <strong>${translate('userDetails.fullName')}</strong>: ${owner.firstName} ${
    owner.lastName
  }<br/>
  <strong>${translate('loanApplication.commercial.ownership')}</strong>: ${
    owner.ownership
  }<br/>
  <strong>${translate('loanApplication.commercial.dateOfBirth')}</strong>: ${
    canSeeSensitiveData
      ? moment(owner.dateOfBirth).format(dateFormatUS)
      : '******'
  }<br/>
  <strong>${translate('userDetails.ssn')}</strong>: ${
    canSeeSensitiveData ? owner.last4SSN : '******'
  }<br/>
  <strong>${translate(
    'loanApplication.commercial.annualGrossIncome'
  )}</strong>: $${formatInUSFormat(owner.annualGrossIncome)}<br/>
  <strong>${translate(
    'loanApplication.commercial.homeAddress'
  )}</strong>: ${formatAddress(owner.homeAddress)}<br/>
  `
}
