import Api from 'easy-fetch-api'
import { history } from 'components/history'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { downloadFileBase64 } from 'common/utils'
import { convertDateToClient } from 'common/date'
import { getAbortController } from 'api-middleware'
import {
  achDataToClientFormat,
  formatNtpsToClient,
  formatTimeForHistoricalEntry,
  formDataToClientFormat,
  createLoanFormDataFromQueryParams,
  formDataToClientFormatCommercial,
} from './utils'
import {
  LOAN_APP_TYPES,
  STEPS_MAP,
  STEPS_MAP_COMMERCIAL,
  STEP_URLS,
  STEP_URLS_COMMERCIAL,
} from './constants'

export const ACTIONS = {
  INIT_FLOW: 'flow.init',
  INIT_FLOW_COMMERCIAL: 'flow.initCommercial',
  RESET_FLOW: 'flow.reset',
  SET_LOADING: 'flow.setLoading',
  SET_STEP: 'flow.setStep',
  SET_LOAN_DATA: 'flow.setLoanData',
  SET_ACH_DATA: 'flow.setAchData',
  SET_POSSIBLE_STATES: 'flow.setPossibleStates',
  SET_ENVELOPES: 'flow.setEnvelopes',
  SET_REASONS: 'flow.setReasons',
  SET_STATUSES: 'flow.setStatuses',
  SWITCH_TRANSLATIONS_LANGUAGE: 'flow.switchTranslationsLanguage',
  INCREMENT_RE_EVALUATION_COUNT: 'flow.incrementReEvaluationCount',
  PREFILL_LOAN_DATA_FROM_QUERY: 'flow.prefillLoanFormDataFromQuery',
  SET_STIPULATIONS: 'flow.setStipulations',
  SET_STIPULATIONS_COMBINED: 'flow.setStipulationsCombined',
  UPDATE_STIPULATION_STATUS: 'flow.updateStipulationStatus',
  UPDATE_STIPULATION_STATUS_FOR_COMBINED:
    'flow.updateStipulationStatusForCombined',
  REQUEST_FUNDING_REVERSAL: 'flow.requestFundingReversal',
  GRANT_FUNDING_REVERSAL: 'flow.grantFundingReversal',
}

/**
 *
 * FLOW GENERAL METHODS
 *
 */

/** Initialize the flow (new flow or loaded from the server) */
export const initFlow = (
  dispatch,
  crtStep = 0,
  loanApplicationId,
  isDefaultLoanFormData
) =>
  dispatch({
    type: ACTIONS.INIT_FLOW,
    crtStep,
    loanApplicationId,
    isDefaultLoanFormData,
  })

/** Initialize the flow for a commercial app (new flow or loaded from the server) */
export const initFlowCommercial = (
  dispatch,
  crtStep = 0,
  loanApplicationId,
  isDefaultLoanFormData
) =>
  dispatch({
    type: ACTIONS.INIT_FLOW_COMMERCIAL,
    crtStep,
    loanApplicationId,
    isDefaultLoanFormData,
  })

/** Reset flow */
export const resetFlow = (dispatch) => dispatch({ type: ACTIONS.RESET_FLOW })

export const setLoading = (dispatch, loading) =>
  dispatch({ type: ACTIONS.SET_LOADING, loading })

/**
 * Go to next step
 * @param {Function} dispatch
 * @param {Function} navigate navigate from 'react-router-dom' useNavigate() hook
 * @param {Number} crtStep current step #
 * @param {Object} [stepData] optional step data
 * @param {Boolean} [isCommercialApp]
 */
export const nextStep = (
  dispatch,
  navigate,
  crtStep,
  stepData,
  isCommercialApp = false
) => {
  const stepsMap = isCommercialApp ? STEPS_MAP_COMMERCIAL : STEPS_MAP

  const nextStep = crtStep + 1
  const hasNextStep = Object.keys(stepsMap).length > nextStep
  if (hasNextStep) {
    dispatch({
      type: ACTIONS.SET_STEP,
      step: nextStep,
    })
    _changeUrlByStep(nextStep, stepData, isCommercialApp, navigate)
  }
}

/** Go to a specific step */
export const goToStep = (
  dispatch,
  navigate /* navigate from 'react-router-dom' useNavigate() hook*/,
  step = 0,
  isCommercialApp = false
) => {
  dispatch({ type: ACTIONS.SET_STEP, step })
  _changeUrlByStep(step, {}, isCommercialApp, navigate)
}

/**
 *
 * API ACTIONS
 *
 */

/**
 * Get Loan Application Data + Loan Application States
 * and persist the information in the store (if "dispatch" is present)
 */
export const getLoanApplication = (dispatch, loanApplicationId, errorCb) =>
  Api.get({
    url: `/LoanApplication/${loanApplicationId}`,
    // Add the signal in order to be able to abort
    signal: getAbortController().signal,
  })
    .then(async (loanApp) => {
      if (!loanApp) {
        return
      }

      if (dispatch) {
        _saveLoanApplicationInTheStore(dispatch, loanApp)
        // After the app is loaded also load the possible states
        await getLoanAppStates(
          dispatch,
          loanApp.loanApplicationState?.id,
          loanApp.loanApplicationType.name === LOAN_APP_TYPES.COMMERCIAL,
          loanApp.isPtoNotMandatory
        )
      }
      return loanApp
    })
    .catch((e) => {
      errorCb && errorCb(e)
      history.replace('/applications')
    })

/** Create/Update a Loan Application */
export const submitLoanApp = (loanApplication, dispatch, navigate) => {
  return Api.put({
    url: '/LoanApplication/submit-residential-loan-application',
    data: loanApplication,
  })
    .then(async (res) => {
      _setLoanApplicationData(dispatch, res)
      await getLoanAppStates(
        dispatch,
        res.loanApplicationState?.id,
        false,
        res.isPtoNotMandatory
      )
      nextStep(dispatch, navigate, STEPS_MAP.NEW_LOAN_APPLICATION, res)
    })
    .catch(async (error) => {
      if (error?.entityId) {
        return await getLoanApplication(dispatch, error.entityId)
      }
    })
}

/** Create/Update a Loan Application */
export const submitCommercialLoanApp = (
  dispatch,
  loanApplication,
  navigate
) => {
  return Api.put({
    url: '/LoanApplication/submit-commercial-loan-application',
    data: loanApplication,
  })
    .then(async (res) => {
      _setLoanApplicationData(dispatch, res)
      await getLoanAppStates(
        dispatch,
        res.loanApplicationState?.id,
        true,
        res.isPtoNotMandatory
      )

      nextStep(
        dispatch,
        navigate,
        STEPS_MAP_COMMERCIAL.NEW_LOAN_APPLICATION,
        res,
        true
      )
    })
    .catch(async (error) => {
      if (error?.entityId) {
        return await getLoanApplication(dispatch, error.entityId)
      }
    })
}

/** Get type of loan app (residential or commercial) */
export const getLoanApplicationType = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/type`,
  })
    .then((res) => res)
    .catch(console.error)
}

/** Get Possible Loan Application States based on the current state and compute the possible steps based on them */
const getLoanAppStates = (
  dispatch,
  currentStateId,
  isCommercialApp,
  isPtoNotMandatory
) =>
  Api.get({
    url: '/LoanApplication/state-guide',
    query: { currentStateId, isPtoNotMandatory },
    // Add the signal in order to be able to abort
    signal: getAbortController().signal,
  }).then((response) => {
    if (!response) {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while fetching the Loan Application State`,
      })
    } else {
      const {
        stateId,
        viewPossibleStateIds = [],
        editPossibleStateIds = [],
      } = response
      dispatch({
        type: ACTIONS.SET_POSSIBLE_STATES,
        possibleStates: editPossibleStateIds.concat([stateId]),
        possibleViewOnlyStates: viewPossibleStateIds,
        isCommercialApp: isCommercialApp,
        isPtoNotMandatory,
      })
    }
  })

/** Get list of possible ID Types for the ID Type field */
export const getIDTypes = () =>
  Api.get({ url: `/LoanApplication/id-types` }).then((list) =>
    list.map((el) => ({
      id: el.id,
      value: el.friendlyName,
      translationKey: `loanApplication.step1.idType.${el.id}`,
    }))
  )

/** Evaluate app loan form */
export const getEvaluationSessions = (loanApplicationId) =>
  Api.get({
    url: `/LoanApplication/${loanApplicationId}/evaluation-sessions`,
    query: { includeDecisioning: true },
  })

/** Evaluate app loan form */
export const getSelfServiceCriterias = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/self-serivce-criterias`,
  }).catch(console.error)
}

/** Get Associated Loan Products for a loan app */
export const getAssociatedLoanProducts = (loanAppId) =>
  Api.get({
    url: `/LoanApplication/${loanAppId}/associated-loan-products`,
  }).catch(console.error)

/** Get Associated Loan Products for a loan app */
export const getAgreementStatuses = () => {
  return Api.get({
    url: `/LoanApplication/agreement-statuses`,
  }).catch(console.error)
}
/** Evaluate a different loan product on an existing loan application */
export const evaluateDifferentLoan = (loanApplicationId, loanProductId) =>
  Api.post({
    url: `/LoanApplication/evaluate-different-loan`,
    data: { loanApplicationId, loanProductId },
  }).catch(console.error)

/** Override a Decision */
export const overrideDecisionAndGetStatus = async (data, dispatch) =>
  Api.put({
    url: `/LoanApplication/override-decision`,
    data: data,
  })
    .then(() => true)
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred overriding decision. Please refresh the page and try again. ${error.title}`,
      })
      return false
    })

/** Remove Override from Decision */
export const removeOverrideOnDecision = (evaluationData, currentLoans) => {
  const { evaluatedLoanDecisionId } = evaluationData.check
  const evaluationSessionArray =
    currentLoans[evaluationData.loanIndex]?.evaluationSessionIds?.slice(-1) ||
    []
  const evaluationSessionId =
    evaluationSessionArray[evaluationSessionArray.length - 1]

  return Api.put({
    url: `/LoanApplication/revert-override-decision`,
    data: {
      evaluatedLoanDecisionId,
      evaluationSessionId,
    },
  }).catch(console.error)
}

/** Choose a loan and save the updated Loan Application to the store */
export const chooseLoan = (dispatch, loanApplicationId, loanProductId) =>
  Api.post({
    url: '/LoanApplication/select-loan',
    data: { loanApplicationId, loanProductId },
  }).then(async (result) => {
    if (!result || !result.loanApplicationId) {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while selecting the loan`,
      })
      throw new Error('Could not select loan')
    }
    // Get the updated Loan Application and save it to the store
    return await getLoanApplication(dispatch, loanApplicationId)
  })

export const updateLoanAmount = (dispatch, loanApplicationId, loanAmount) =>
  Api.put({
    url: '/LoanApplication/update-loan-amount',
    data: { loanApplicationId, loanAmount },
  })
    .then(async () => {
      // Get the updated Loan Application and save it to the store
      const newLoanApp = await getLoanApplication(dispatch, loanApplicationId)
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `Successfully changed loan amount.`,
      })
      incrementReEvaluationCount(dispatch)
      return newLoanApp
    })
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Something went wrong.`,
      })

      console.error(error)
    })

export const addCoBorrower = (dispatch, loanApplicationId, coBorrower) =>
  Api.put({
    url: '/LoanApplication/add-coborrower',
    data: { loanApplicationId, coBorrower },
  })
    .then(async () => {
      // Get the updated Loan Application and save it to the store
      const newLoanApp = await getLoanApplication(dispatch, loanApplicationId)
      incrementReEvaluationCount(dispatch)
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `Successfully added co-borrower.`,
      })
      return newLoanApp
    })
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Something went wrong when adding co-borrower.`,
      })

      console.error(error)
    })
export const updateBorrowerName = (
  dispatch,
  loanApplicationId,
  borrowerId,
  firstName,
  lastName
) =>
  Api.put({
    url: '/LoanApplication/update-borrower-name',
    data: { loanApplicationId, borrowerId, firstName, lastName },
  })
    .then(async () => {
      // Get the updated Loan Application and save it to the store
      const newLoanApp = await getLoanApplication(dispatch, loanApplicationId)
      incrementReEvaluationCount(dispatch)

      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `Successfully updated property title.`,
      })
      return newLoanApp
    })
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Something went wrong when changing property title name.`,
      })

      console.error(error)
    })
/** Get lender agreement */
export const getLenderAgreement = (loanApplicationId) =>
  Api.get({ url: `/LoanApplication/${loanApplicationId}/lender-agreement` })

/** Update lender agreement */
export const updateLenderAgreement = (
  dispatch,
  loanApplicationId,
  lenderAgreementStatusId
) =>
  Api.put({
    url: `/LoanApplication/accept-lender-agreement`,
    data: { loanApplicationId, lenderAgreementStatusId },
  }).then(async (result) => {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: `Agreement successfully updated`,
    })

    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Get ACH agreement */
export const getAchAgreement = (loanApplicationId) =>
  Api.get({ url: `/LoanApplication/${loanApplicationId}/ach-agreement` })

/** Update membership agreement */
export const updateMembershipAgreement = (
  dispatch,
  loanApplicationId,
  membershipApplicationAgreementStatusId,
  other = {}
) =>
  Api.put({
    url: `/LoanApplication/accept-membership-application-verbiage`,
    data: {
      loanApplicationId,
      membershipApplicationAgreementStatusId,
      ...other,
    },
  }).then(async (result) => {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: `Agreement successfully updated`,
    })

    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Submit ACH Information */
export const submitAchData = (dispatch, loanApplicationId, achData) =>
  Api.post({
    url: `/LoanApplication/submit-ach-data`,
    data: { ...achData, loanApplicationId },
  }).then((ach) => {
    _setAchData(dispatch, ach)
    return ach
  })

/**
 * Authorize Loan Documents pre-generation
 * @returns {Promise<Boolean>} true/false
 */
export const authorizeLoanDocuments = (loanApplicationId) =>
  Api.put({
    url: `/LoanApplication/prepare-for-documents-generation`,
    data: {
      loanApplicationId,
      evaluationRequired: true,
    },
    // Add the signal in order to be able to abort
    signal: getAbortController().signal,
  })
    .then((res) => res && !!res.loanApplicationId)
    .catch(() => false)

export const authorizeLoanDocumentsCommercial = (loanApplicationId) =>
  Api.put({
    url: `/LoanApplication/authorize-commercial-loan-application`,
    data: {
      loanApplicationId,
      evaluationRequired: true,
    },
    // Add the signal in order to be able to abort
    signal: getAbortController().signal,
  })
    .then((res) => res && !!res.loanApplicationId)
    .catch(() => false)

/** Documents - generate Envelopes */
export const generateEnvelopes = ({
  dispatch,
  lenderId,
  loanApplicationId,
  envelopeDefinitionIds,
  loanFormData,
}) => {
  const applicants = [loanFormData.borrowerGeneralDetails]
  if (loanFormData.coBorrowerGeneralDetails) {
    applicants.push(loanFormData.coBorrowerGeneralDetails)
  }

  return Api.post({
    url: `/Envelopes/generate-multiple `,
    data: {
      lenderId,
      envelopeDefinitionIds,
      loanApplicationId,
      redirectUrl: `${window.location.origin}/loan-application/${loanApplicationId}/loan-documents`,
      applicantInfo: applicants.map((applicant) => ({
        email: applicant.emailAddress,
        birthDate: applicant.dateOfBirth,
      })),
      recipients: applicants.map((applicant) => ({
        email: applicant.emailAddress,
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        isSignatureRequired: true,
        roleId: applicant.applicantTypeId,
        recipientId: applicant.applicantId,
      })),
    },
    // Add the signal in order to be able to abort
    signal: getAbortController().signal,
  })
    .then((envelopes) => !!envelopes)
    .catch(() => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Error generating loan documents. Please try again.`,
      })

      throw new Error('Error generating loan documents')
    })
}

/** Void an envelope */
export const voidEnvelope = (loanApplicationId, envelopeId) =>
  Api.post({
    url: `/Envelopes/void`,
    data: { loanApplicationId, envelopeIds: [envelopeId] },
  })

/** Check for updates on Envelopes - used after Signing via DocuSign s.t. we don't have to wait for the webhook to respond */
export const checkForEnvelopeUpdates = (dispatch, loanApplicationId) =>
  Api.post({
    url: `/Envelopes/check-for-updates`,
    data: { loanApplicationId },
    // Add the signal in order to be able to abort
    signal: getAbortController().signal,
  })
    .then((envelopes) => {
      dispatch && dispatch({ type: ACTIONS.SET_ENVELOPES, envelopes })
      return envelopes
    })
    .catch(() => {
      return null
    })

export const listEnvelopesByApplication = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/Envelopes/list-envelopes-application/${loanApplicationId}`,
  }).catch(() => {
    return null
  })

/** Re-send an envelope via email */
export const emailEnvelopes = (envelopeId) =>
  Api.post({ url: `/Envelopes/re-send-envelope`, data: { envelopeId } })

export const sendCompletedEnvelopes = (envelopeId) =>
  Api.post({
    url: `/Envelopes/send-completed-envelopes-email`,
    data: { envelopeId },
  })

export const reAttachEnvelopes = (loanApplicationId, envelopeIds) =>
  Api.post({
    url: `/Envelopes/re-attach-completed`,
    data: {
      loanApplicationId,
      envelopeIds: envelopeIds,
    },
  })

export const getOldCompletedEnvelopes = (loanApplicationId) =>
  Api.get({
    url: `/LoanApplication/${loanApplicationId}/old-completed-envelopes`,
  }).catch(console.error)

/** NTP - get all NTPs for the Loan App */
export const getNTPs = (loanApplicationId, isFinal = false) =>
  Api.get({
    url: `/LoanApplication/${loanApplicationId}/ntp`,
    query: { isFinal },
  }).then(formatNtpsToClient)

export const getNTPsBySectionType = (dispatch, loanApplicationId, ntpTypeId) =>
  Api.get({
    url: `/LoanApplication/${loanApplicationId}/ntp-section`,
    query: { ntpTypeId },
  })
    .then(formatNtpsToClient)
    .then((result) => {
      dispatch({
        type: ACTIONS.SET_STIPULATIONS,
        ntps: result.applicationNTPs,
      })
      return result
    })

export const getAllNTPs = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/LoanApplication/${loanApplicationId}/ntp-sections`,
  }).then((result) => {
    dispatch({
      type: ACTIONS.SET_STIPULATIONS_COMBINED,
      ntps: {
        initialNtps: result.ntpSections[0].applicationNTPs,
        finalNtps: result.ntpSections[1].applicationNTPs,
      },
    })
    return result
  })

export const getCreditReport = (applicationId, lenderId) => {
  return Api.get({
    url: `/LoanApplication/${applicationId}/friendly-credit-report-info`,
    query: { lenderId },
  }).catch(console.error)
}
/** Upload NTP */
export const uploadNTP = (
  dispatch,
  applicationNTPId,
  { name, content, mimeType }
) =>
  Api.put({
    url: `/LoanApplication/upload-user-ntp`,
    data: {
      applicationNTPId,
      name,
      content,
      mimeType,
    },
  }).catch(console.error)

/** Delete an uploaded NTP document */
export const deleteUserNTP = (dispatch, loanApplicationId, userNTPId) =>
  Api.delete({
    url: `/LoanApplication/delete-user-ntp`,
    query: { userNTPId },
  })
    .then(async (result) => {
      await getLoanApplication(dispatch, loanApplicationId)
      return result
    })
    .catch(console.error)

/** Download all NTPs */
export const downloadAllAttachments = (ntpSectionId) =>
  Api.post({
    url: `/LoanApplication/download-ntp-attachments`,
    data: { ntpSectionId },
  }).then(downloadFileBase64)

/** Download deleted NTP attachments by stipulation definition */
export const downloadDeletedNtpAttachments = (
  loanApplicationId,
  stipulationDefinitionsIds,
  applicantTypeId
) =>
  Api.post({
    url: `/LoanApplication/download-deleted-ntp-attachments`,
    data: {
      loanApplicationId,
      stipulationDefinitionsIds,
      applicantTypeId,
    },
  }).then(downloadFileBase64)

export const downloadAllDeletedAttachments = (loanApplicationId) => {
  return Api.post({
    url: `/LoanApplication/download-all-deleted-attachments`,
    data: {
      loanApplicationId,
    },
  }).then(downloadFileBase64)
}

export const downloadAllFiles = (loanApplicationId) => {
  return Api.post({
    url: `/LoanApplication/download-all-attachments`,
    data: { loanApplicationId },
  })
    .then(downloadFileBase64)
    .catch(console.error)
}

export const downloadDocuments = (loanApplicationId) => {
  return Api.post({
    url: `/LoanApplication/download-documents`,
    data: { loanApplicationId },
  })
    .then(downloadFileBase64)
    .catch(console.error)
}

/** Submit NTP comment */
export const submitNTPComment = (loanApplicationId, comment) =>
  Api.post({
    url: `/LoanApplication/ntp-comment`,
    data: { loanApplicationId, content: comment },
  })

export const removeNTPComment = (commentId) => {
  return Api.delete({
    url: `/LoanApplication/remove-ntp-comment`,
    query: { NTPCommentId: commentId },
  }).catch(console.error)
}

/** Enhance NTP */
export const enhanceNTP = (ntpSectionId, hasEnhancedInitialFunding) =>
  Api.put({
    url: `/LoanApplication/set-enhanced-initial-funding`,
    data: { ntpSectionId, hasEnhancedInitialFunding },
  })

/** Set NTP PTO Date */
export const setPTODate = (ntpSectionId, ptoDate) => {
  Api.put({
    url: `/LoanApplication/edit-pto-date`,
    data: { ntpSectionId, ptoDate },
  })
}

export const updateUserNtpStatus = (dispatch, loanApplicationId, result) => {
  const { statusId, userNTPId, rejectedDate } = result
  dispatch({
    type: ACTIONS.UPDATE_STIPULATION_STATUS,
    payload: {
      userNTPId,
      statusId,
      rejectedDate,
    },
  })

  // The app might go in another App State so we need to re-fetch
  getLoanApplication(dispatch, loanApplicationId)

  return result
}

export const updateUserNtpStatusForCombined = (
  dispatch,
  loanApplicationId,
  result,
  isInitial
) => {
  const { statusId, userNTPId, rejectedDate } = result
  dispatch({
    type: ACTIONS.UPDATE_STIPULATION_STATUS_FOR_COMBINED,
    payload: {
      userNTPId,
      statusId,
      rejectedDate,
      isInitial,
    },
  })

  // The app might go in another App State so we need to re-fetch
  getLoanApplication(dispatch, loanApplicationId)

  return result
}

/** Change the Status of a NTP User Document */
export const setUserNTPStatus = (userNTPId, { statusId }) =>
  Api.put({
    url: `/LoanApplication/set-user-ntp-status`,
    data: { userNTPId, statusId },
  })

/** Submit NTP For Approval */
export const submitNTPForApproval = (dispatch, isInitial, loanApplicationId) =>
  Api.put({
    url: `/LoanApplication/submit-${isInitial ? 'initial' : 'final'}-ntp`,
    data: { loanApplicationId },
  }).then(async (result) => {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: `${isInitial ? 'Initial' : 'Final'} NTP submitted for approval`,
    })
    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Approve NTP */
export const approveNTP = (
  dispatch,
  isInitial,
  isCommercial,
  loanApplicationId
) =>
  Api.put({
    url: `/LoanApplication/approve-${
      isCommercial ? 'commercial' : isInitial ? 'initial' : 'final'
    }-ntp`,
    data: { loanApplicationId },
  }).then(async (result) => {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: `${
        isCommercial ? 'Commercial' : isInitial ? 'Initial' : 'Final'
      } NTP approved`,
    })
    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Approve NTP */
export const approveNTPForCombined = (
  dispatch,
  isInitial,
  isCommercial,
  loanApplicationId
) =>
  Api.put({
    url: `/LoanApplication/approve-${
      isCommercial ? 'commercial' : isInitial ? 'initial' : 'final'
    }-ntp`,
    data: { loanApplicationId },
  }).then(async (result) => {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: `${
        isCommercial ? 'Commercial' : isInitial ? 'Initial' : 'Final'
      } NTP approved`,
    })
    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Add Stipulation(s) on an NTP */
export const addStipulationsNTP = (
  dispatch,
  loanApplicationId,
  ntpSectionId,
  stipulations
) => {
  return Promise.all(
    stipulations.map((stipulationDefinitionId) =>
      Api.post({
        url: `/LoanApplication/add-application-ntp`,
        data: { ntpSectionId, stipulationDefinitionId },
      })
    )
  ).then(async (result) => {
    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })
}

export const deleteStipulationNTP = (
  dispatch,
  applicationNTPId,
  loanApplicationId
) =>
  Api.delete({
    url: `/LoanApplication/delete-application-ntp`,
    query: { applicationNTPId },
  }).then(async (result) => {
    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Request NTP Funds */
export const requestFunds = (dispatch, isInitial, loanApplicationId) =>
  Api.put({
    url: `/LoanApplication/request-${isInitial ? 'initial' : 'final'}-funds`,
    data: { loanApplicationId },
  }).then(async (result) => {
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: `${isInitial ? 'Initial' : 'Final'} funds requested`,
    })
    await getLoanApplication(dispatch, loanApplicationId)
    return result
  })

/** Request NTP Funds */
export const grantFunds = (
  dispatch,
  isInitial,
  loanApplicationId,
  initialFundingAmount
) =>
  Api.put({
    url: `/LoanApplication/grant-${isInitial ? 'initial' : 'final'}-funding`,
    data: { loanApplicationId, initialFundingAmount },
  }).then(async (result) => {
    if (result) {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `${isInitial ? 'Initial' : 'Final'} funds granted`,
      })
      return await getLoanApplication(dispatch, loanApplicationId)
    } else {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: 'Something went wrong. Please try again',
      })
      return null
    }
  })

/** Update Funding Info */
export const updateFundingInfo = (dispatch, loanApplicationId, fundingInfo) => {
  const {
    initialFundingAmount,
    initialNetFundingAmount,
    finalNetFundingAmount,
    initialFundingGrantedDate,
    amountPaidToThirdParty,
    dateOfAmountPaidToThirdParty,
    useInitialFundingThirdParty,
    finalGrossFundingAmount,
    loanPaymentDueDate,
  } = fundingInfo

  return Api.put({
    url: '/LoanApplication/update-funding',
    data: {
      loanApplicationId,
      initialFundingAmount,
      initialNetFundingAmount,
      finalNetFundingAmount,
      initialFundingGrantedDate,
      amountPaidToThirdParty,
      dateOfAmountPaidToThirdParty,
      useInitialFundingThirdParty,
      finalGrossFundingAmount,
      loanPaymentDueDate,
    },
  }).then(async (loanApp) => {
    await getLoanApplication(dispatch, loanApplicationId)
    showNotification(dispatch, { title: 'Funding info updated' })
    return loanApp
  })
}

/** Request Funding Info Reversal */
export const requestFundReversal = (
  dispatch,
  loanApplicationId,
  reason,
  requestDate
) =>
  Api.put({
    url: '/LoanApplication/request-funding-reversal',
    data: { loanApplicationId, reason, requestDate },
  })
    .then((response) => {
      if (response.loanApplicationId) {
        showNotification(dispatch, { title: 'Requested Funding Info Reversal' })
        dispatch({
          type: ACTIONS.REQUEST_FUNDING_REVERSAL,
          reason,
          requestDate: response.fundingReversalRequestDate,
        })
      } else {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: 'Something went wrong. Please try again',
        })
      }
    })
    .catch(console.error)

/** Funding Info Reversal */
export const fundingReversal = (dispatch, loanApplicationId, reverseDate) =>
  Api.put({
    url: '/LoanApplication/funding-reversal',
    data: { loanApplicationId, reverseDate },
  }).then((response) => {
    if (response.loanApplicationId) {
      showNotification(dispatch, { title: 'Funding Info Reversed' })
      dispatch({
        type: ACTIONS.GRANT_FUNDING_REVERSAL,
        fundingReversalDate: response.fundingReversalDate,
      })
    } else {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: 'Something went wrong. Please try again',
      })
    }
  })

export const getCommunicationMethods = () =>
  Api.get({ url: '/LoanApplication/preferred-communication-methods' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step1.preferredMethodOfCommunication.${el.id}`,
      }))
    )
    .catch(console.error)

export const getAdditionalIncomeTypes = () =>
  Api.get({ url: '/LoanApplication/additional-income-types' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step1.additionalIncomeType.${el.id}`,
      }))
    )
    .catch(console.error)

export const getLoanAppStatuses = (dispatch) =>
  Api.get({ url: `/LoanApplication/loan-application-statuses` })
    .then((res) => {
      const statuses = res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.statusCard.loanApplicationStatus.${el.id}`,
      }))
      dispatch && dispatch({ type: ACTIONS.SET_STATUSES, statuses })
      return statuses
    })
    .catch(console.error)

export const getLoanAppStatusReasons = (dispatch) =>
  Api.get({ url: `/LoanApplication/status-reasons` })
    .then((reasons) => dispatch({ type: ACTIONS.SET_REASONS, reasons }))
    .catch(console.error)

export const updateLoanAppStatus = ({
  dispatch,
  loanApplicationId,
  loanApplicationStatusId,
  loanApplicationStatusReasonNote,
  loanApplicationStatusReasonId,
}) =>
  Api.put({
    url: `/LoanApplication/update-loan-application-status`,
    data: {
      loanApplicationId,
      loanApplicationStatusId,
      loanApplicationStatusReasonNote,
      loanApplicationStatusReasonId,
    },
  })
    .then(() => {
      return getLoanApplication(dispatch, loanApplicationId)
    })
    .catch(() =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: 'An error occurred while updating the Loan App Status',
      })
    )

export const getNotes = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/notes`,
  })
    .then((notes) =>
      notes?.map((note) => ({
        ...note,
        dateCreated: convertDateToClient(note.dateCreated),
      }))
    )
    .catch(console.error)
}

export const getMembershipVerbiage = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/membership-application-verbiage`,
  }).catch(console.error)
}

export const addNotes = (data) => {
  return Api.post({
    url: `/LoanApplication/add-loan-application-note`,
    data: data,
  })
}

export const updateNotes = (data) => {
  return Api.put({
    url: `/LoanApplication/update-loan-application-note`,
    data: data,
  }).catch(console.error)
}

export const removeNote = (noteId) => {
  return Api.delete({
    url: `/LoanApplication/delete-loan-application-note`,
    query: { loanApplicationNoteId: noteId },
  }).catch(console.error)
}

export const downloadSingleAttachment = (dispatch, attachmentId) => {
  return Api.post({
    url: `/LoanApplication/download-single-attachment`,
    data: { attachmentId: attachmentId },
  })
    .then((res) => {
      if (!res) {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: `An error occurred while downloading the attachment`,
        })
      }
      return res
    })
    .catch(() =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while downloading the attachment`,
      })
    )
}

export const downloadMultipleAttachments = (
  dispatch,
  loanApplicationId,
  attachmentIds
) =>
  Api.post({
    url: `/LoanApplication/download-multiple-attachments`,
    data: { loanApplicationId, attachmentIds },
  })
    .then((res) => res && downloadFileBase64(res))
    .catch(console.error)

export const downloadCreditReport = (data) => {
  return Api.post({
    url: '/LoanApplication/download-credit-report-pdf',
    data: data,
  }).catch(console.error)
}

export const rePullCreditReport = (data) => {
  return Api.post({
    url: '/LoanApplication/pull-credit-report',
    data: data,
  }).catch(console.error)
}

export const getEmploymentStatuses = () =>
  Api.get({ url: '/LoanApplication/employment-statuses' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step1.employmentStatus.${el.id}`,
      }))
    )
    .catch(console.error)

export const getResidencyOccupationTypes = () =>
  Api.get({ url: '/LoanApplication/residency-occupation-types' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step1.residencyOccupationType.${el.id}`,
      }))
    )
    .catch(console.error)

export const getCitizenships = () =>
  Api.get({ url: '/LoanApplication/citizenships' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step1.citizenshipType.${el.id}`,
      }))
    )
    .catch(console.error)

export const getMLAs = () =>
  Api.get({ url: '/LoanApplication/military-affiliation' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step1.militaryAffiliation.${el.id}`,
      }))
    )
    .catch(console.error)

export const getAccountTypes = () =>
  Api.get({ url: '/LoanApplication/account-types' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.step3.accountType.${el.id}`,
      }))
    )
    .catch(console.error)

export const getLoanAppHistory = (loanApplicationId) => {
  return Api.get({
    url: '/LoanApplication/history',
    query: { modifiedEntityId: loanApplicationId },
  })
    .then((res) => res?.map(formatTimeForHistoricalEntry))
    .catch(console.error)
}

export const getEmailHistory = (loanApplicationId) => {
  return Api.get({
    url: '/History/emails',
    query: { modifiedEntityId: loanApplicationId },
  })
    .then((res) => res?.map(formatTimeForHistoricalEntry))
    .catch(console.error)
}

export const getPropertyTitle = (propertyTitleReport) => {
  return Api.get({
    url: `/LoanApplication/property-title-report`,
    query: { propertyTitleReport: propertyTitleReport },
  })
}

export const getIncomeEstimatorData = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/income-estimator`,
  }).catch((error) => console.error(error))
}

export const getDtiSummary = (loanApplicationId, evaluatedLoanProductId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/dti-summary`,
    query: { evaluatedLoanProductId },
  })
}

export const getDtiSummaryForSelectedLoanProduct = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/selected-loan-dti-summary`,
  })
}

export const submitSummary = (data) => {
  return Api.post({
    url: `/LoanApplication/summary-data`,
    data: data,
  }).catch(console.error)
}

export const checkOrganizationHasSpecialFields = (orgId) => {
  return Api.get({
    url: `/LoanApplication/has-special-fields`,
    query: { organizationId: orgId },
  }).catch((error) => console.error(error))
}

export const switchTranslationsLanguage = (dispatch, selectedLanguage) => {
  dispatch({
    type: ACTIONS.SWITCH_TRANSLATIONS_LANGUAGE,
    selectedLanguage,
  })
}

export const reEvaluateLoanApplication = (data) => {
  return Api.post({
    url: `/LoanApplication/re-evaluate-loan-application`,
    data: data,
  }).catch(console.error)
}

export const extendLoanApplicationExpiration = (data, dispatch) => {
  return Api.post({
    url: `/LoanApplication/extend-expiration-period`,
    data: data,
  })
    .then(async (result) => {
      await getLoanApplication(dispatch, data.loanApplicationId)

      showNotification(dispatch, {
        title: `Successfully extended loan application expiration date.`,
      })

      return result
    })
    .catch(console.error)
}

export const expireLoanApplication = (data, dispatch) => {
  return Api.patch({
    url: `/LoanApplication/${data.loanApplicationId}/expire`,
  })
    .then(async (result) => {
      await getLoanApplication(dispatch, data.loanApplicationId)

      showNotification(dispatch, {
        title: `Successfully expired loan application.`,
      })

      return result
    })
    .catch(console.error)
}

export const incrementReEvaluationCount = (dispatch) => {
  dispatch({
    type: ACTIONS.INCREMENT_RE_EVALUATION_COUNT,
  })
}

export const calculateIncomeToBeProven = (loanApplicationId) => {
  return Api.post({
    url: `/LoanApplication/calculate-income-to-be-proven`,
    data: { loanApplicationId },
  }).catch(console.error)
}

export const acceptAttestationVerbiage = (loanApplicationId) => {
  return Api.post({
    url: `/LoanApplication/accept-attestation-verbiage`,
    data: { loanApplicationId },
  }).catch((error) => {
    console.error(error)
    throw new Error('Error accepting attestation verbiage')
  })
}

export const getPTOData = (ptoId) =>
  Api.get({
    url: '/LoanApplication/pto',
    query: { ptoId },
  }).catch(console.error)

export const submitPTO = (data, loanApplicationId, dispatch) => {
  return Api.post({
    url: `/LoanApplication/submit-pto`,
    data: data,
  })
    .then(async (result) => {
      await getLoanApplication(dispatch, loanApplicationId)
      return result
    })
    .catch(console.error)
}

export const approvePTO = (loanApplicationId, dispatch, navigate) => {
  return Api.post({
    url: `/LoanApplication/approve-pto`,
    data: { loanApplicationId },
  })
    .then(async (result) => {
      await getLoanApplication(dispatch, loanApplicationId)
      goToStep(dispatch, navigate, STEPS_MAP.CONGRATS)
      return result
    })
    .catch(console.error)
}

export const declinePTO = (loanApplicationId, declineReason, dispatch) => {
  return Api.post({
    url: `/LoanApplication/decline-pto`,
    data: { loanApplicationId, declineReason },
  })
    .then(async (result) => {
      await getLoanApplication(dispatch, loanApplicationId)
      return result
    })
    .catch(console.error)
}

/** Submit Change Order Request */
export const submitChangeOrderRequest = (
  loanApplicationId,
  changeOrderRequestTypeId,
  reason,
  dispatch
) => {
  return Api.post({
    url: `/LoanApplication/change-order-request`,
    data: { loanApplicationId, changeOrderRequestTypeId, reason },
  })
    .then((res) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        message: `Change Order Request successfully submitted`,
      })
      return res
    })
    .catch(() =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: 'An error occurred while submitting the Change Order Request',
      })
    )
}

/** Get Change Order Request Types */
export const getChangeOrderRequestTypes = () =>
  Api.get({ url: '/LoanApplication/change-order-request-types' })
    .then((res) =>
      res.map((el) => ({
        id: el.id,
        value: el.friendlyName,
        translationKey: `loanApplication.ecgToolbox.changeOrderRequestModal.changeOrderRequestType.${el.id}`,
      }))
    )
    .catch(console.error)

/** Get Existing Change Order Requests */
export const getOrderRequests = (loanApplicationId) => {
  return Api.get({
    url: `/LoanApplication/${loanApplicationId}/change-order-requests`,
  }).catch(console.error)
}
/**
 *
 * HELPER METHODS
 *
 */

/**
 * Helper method that changes the current Flow url based on a step index
 * @param {Number} stepIndex the step index to change to
 * @param {Object} [stepData] optional step data
 * @param {Boolean} [isCommercialApp]
 * @private
 */
const _changeUrlByStep = (
  stepIndex,
  stepData = {},
  isCommercialApp,
  navigate
) => {
  const stepUrls = isCommercialApp ? STEP_URLS_COMMERCIAL : STEP_URLS

  const nextStep = Object.keys(stepUrls).find(
    (stepKey) => stepUrls[stepKey].idx === stepIndex
  )
  if (nextStep) {
    let urlParts = window.location.pathname.split('/')
    // Special edge case for new flow when it's accessed via /new without a valid stepPath
    if (stepIndex === 0 && urlParts.length === 3) {
      urlParts = urlParts.concat([stepUrls[nextStep].path])
    }
    // Special edge case for new flow when passing from step 0 to step 1
    if (stepIndex === 1 && urlParts[2] === 'new') {
      urlParts[2] = stepData.loanApplicationId
    }
    // New URL is: remove last part of the old one (the step path) + add the new step path
    // e.g. step 1->2: loan-application/new/new-loan-app -> trim "new-loan-app", concat "select-loan" -> loan-application/new/select-loan
    const newUrl = urlParts
      .splice(0, urlParts.length - 1)
      .concat([stepUrls[nextStep].path])
      .join('/')

    navigate(newUrl, { stepData })
  }
}

/** Save the Loan Application in the Store. If present, also save ACH and other related data */
export const _saveLoanApplicationInTheStore = (dispatch, loanApp) => {
  if (!loanApp) {
    return {}
  }

  const { ach, ...loanFormData } = loanApp
  _setLoanApplicationData(dispatch, loanFormData)
  if (ach) {
    _setAchData(dispatch, ach)
  }
  return loanApp
}

/** Parse loan app data to the client format and store it */
const _setLoanApplicationData = (dispatch, loanApp) => {
  const {
    selectedLoanProductId,
    selectedLoanProduct,
    loanApplicationState,
    loanApplicationStatus,
    isHardCreditPulled,
    loanApplicationType,
    ...loanFormData
  } = loanApp

  const data =
    loanApplicationType.name === LOAN_APP_TYPES.RESIDENTIAL
      ? formDataToClientFormat(loanFormData)
      : formDataToClientFormatCommercial(loanFormData)

  dispatch({
    type: ACTIONS.SET_LOAN_DATA,
    data: {
      ...data,
      selectedLoanProductId,
      selectedLoanProduct,
      loanApplicationState,
      loanApplicationStatus,
      isHardCreditPulled,
    },
  })
}

/** Parse ACH form data to the client format and store it */
const _setAchData = (dispatch, data) => {
  dispatch({
    type: ACTIONS.SET_ACH_DATA,
    achData: achDataToClientFormat(data),
  })
}

export const initLoanFormDataBasedOnQueryParams = (
  dispatch,
  query,
  citizenships,
  militaryAffiliation,
  employmentStatuses,
  residencyOccupation,
  communicationMethods,
  additionalIncomeTypes
) => {
  const loanFormData = createLoanFormDataFromQueryParams(
    query,
    citizenships,
    militaryAffiliation,
    employmentStatuses,
    residencyOccupation,
    communicationMethods,
    additionalIncomeTypes
  )

  dispatch({
    type: ACTIONS.PREFILL_LOAN_DATA_FROM_QUERY,
    loanFormData: loanFormData,
  })
}
