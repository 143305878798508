import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ERRORS, PASSWORD_REGEX } from 'components/validator'
import { modules } from '@ElementsCapitalGroup/enium-ui'
import AuthBg from 'assets/auth-bg-desktop.jpg'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import './index.scss'

const { SetPasswordNewUserContainer, PasswordResetContainer } = modules

const SetPasswordForSales = ({
  onSubmit,
  submittedVerbiage: { titleSuccess, titleFailed, textSuccess, textFailed },
}) => {
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const isDesktopView = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`)

  const onFormSubmit = (data, ev) => {
    ev?.preventDefault()
    setLoading(true)
    return onSubmit(data)
      .then((res) => {
        setSubmitted(true)
        if (res && res.firstName) {
          setSubmitted(true)
          setSuccess(true)
        } else {
          setSuccess(false)
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  if (submitted) {
    return (
      <PasswordResetContainer
        title={success ? titleSuccess : titleFailed}
        onContinue={() => {
          if (success) {
            navigate('/login')
          } else {
            setSubmitted(false)
          }
        }}
        supportingText={success ? textSuccess : textFailed}
        failed={!success}
        imageUrl={AuthBg}
      />
    )
  }

  return (
    <SetPasswordNewUserContainer
      onSubmit={onFormSubmit}
      imageUrl={isDesktopView ? AuthBg : null}
      loading={loading}
      title="Welcome to Your Enium Portal"
      supportingText={
        <div>
          <div className="set-password__container">
            <span className="set-password__subtitle">
              Regulatory Compliance
            </span>
            <span className="set-password__subtext">
              As a Sales Representative offering our loan products, you must
              comply with the following federal regulations:
            </span>
            <ul className="set-password__unordered-list">
              <li>Fair Lending</li>
              <li>Equal Credit Opportunity Act (ECOA) – Regulation B</li>
              <li>Regulation P and The Gramm-Leach Bliley Act (GLBA)</li>
              <li>Unfair, Deceptive and Abusive Acts and Practices (UDAAP)</li>
            </ul>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="set-password__subtitle">Operation Guidelines</span>
            <span className="set-password__subtext">
              To ensure compliance when offering Enium loan products, adhere to
              these guidelines:
            </span>
            <ol className="set-password__ordered-list">
              <li>
                <span className="set-password__subitem">No Prejudgement:</span>{' '}
                Allow every customer the opportunity to finance. Do not make
                assumptions based on your perceptions of the customer.
              </li>
              <li>
                <span className="set-password__subitem">
                  Refer Loan Questions:
                </span>{' '}
                Do not discuss specific loan terms. If a customer has questions,
                direct them to Enium.
              </li>
              <li>
                <span className="set-password__subitem">Email Security:</span>{' '}
                Do not send customers’ loan documents to your own email address.{' '}
              </li>
              <li>
                <span className="set-password__subitem">
                  Privacy Protection:
                </span>{' '}
                Safeguard your customers’ privacy. Do not disclose private
                information or credit decisions to anyone.
              </li>
              <li>
                <span className="set-password__subitem">
                  Contractor ID Security:
                </span>{' '}
                Do not allow others to use your contractor ID number.
              </li>
              <li>
                <span className="set-password__subitem">
                  No Discriminatory Practices:
                </span>{' '}
                Do not engage in elder abuse or other discriminatory practices.
              </li>
              <li>
                <span className="set-password__subitem">
                  Invoice Guidelines:
                </span>{' '}
                Do not itemize dealer fees on your invoice.
              </li>
              <li>
                <span className="set-password__subitem">
                  Truth-in-Lending Act Compliance:
                </span>{' '}
                You are not permitted to charge a higher price than the cash
                price for projects that include Enium financing (no cash
                discounts).
              </li>
            </ol>
          </div>
        </div>
      }
      passwordRegex={PASSWORD_REGEX}
      errorMessages={{
        password: ERRORS.PASSWORD,
        confirmPassword: ERRORS.PASSWORD,
      }}
    />
  )
}

SetPasswordForSales.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  supportingText: PropTypes.string.isRequired,
  submittedVerbiage: PropTypes.shape({
    titleSuccess: PropTypes.string.isRequired,
    titleFailed: PropTypes.string.isRequired,
    textSuccess: PropTypes.string.isRequired,
    textFailed: PropTypes.string.isRequired,
  }),
  buttonText: PropTypes.string,
}

export default SetPasswordForSales
