import PropTypes from 'prop-types'
import { useEffect, useMemo, useReducer, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { scrollToClosestError } from 'common/utils'
import Button from 'components/button'
import {
  ADDRESS_TYPES_TRANSLATIONS,
  ORGANIZATION_TYPE_IDS,
} from 'common/constants'
import { getAllOrganizations } from 'modules/organizations/actions'
import {
  setLoading as setGlobalLoading,
  showNotification,
} from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { ReactComponent as WhiteClipperIcon } from 'assets/white-clipper.svg'

import { Building, BusinessOwner, CommercialLoanInfo } from './components'
import {
  CommercialAppContext,
  commercialLoanAppReducer as reducer,
  initCommercialApp,
  initialState,
  fetchAllLoanSignaturesForOrganization,
  validateCommercialLoanApp,
  getTypesOfBusiness,
  setOrganization,
  setAppData,
} from './store'
import './index.scss'
import { formatSubmitCommercialAppPayload } from './utils'
import { submitCommercialLoanApp } from '../actions'
import { validateAddress } from '../utils'
import { copyCommercialAppToClipboard } from './clipboard'
import TermsAndConditionsModals from '../create-loan-app/terms-and-conditions-modals'

export const CreateCommercialLoanApp = ({
  loanApplicationId,
  loanFormData,
  organizations,
  userData,
  dispatch,
}) => {
  const navigate = useNavigate()
  const [commercialAppState, dispatchCommercialApp] = useReducer(
    reducer,
    initialState
  )
  const { organization } = commercialAppState
  const { t: translate } = useTranslation()
  const createLoanAppForm = useRef(null)
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [isAuthAgreed, setIsAuthAgreed] = useState(false)
  const [isTCAgreed, setIsTCAgreed] = useState(false)
  const [isElectronicAgreed, setIsElectronicAgreed] = useState(true)
  const isEdit = !!loanApplicationId
  const isECG = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)
  const hasOrgsAccess = useMemo(
    () => hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS),
    [userData]
  )

  const canSeeSensitiveData = useMemo(
    () => hasAccess(userData, CLAIMS.CAN_DECRYPT_SENSITIVE_DATA),
    [userData]
  )

  const [isCorporateOrg, setIsCorporateOrg] = useState(false)
  const [salesOrganisations, setSalesOrganizations] = useState([])

  useEffect(() => {
    initCommercialApp(dispatchCommercialApp)

    getTypesOfBusiness(dispatchCommercialApp)
    if (!hasOrgsAccess) {
      setOrganization(dispatchCommercialApp, userData.organizationId)
    }

    if (hasOrgsAccess) {
      getAllOrganizations(dispatch)
    }
  }, [])

  /** On organization list change, filter the sales organization and set the isCorporate flag */
  useEffect(() => {
    if (!organizations?.length) {
      return
    }
    const userOrg = userData.organizationId
    setSalesOrganizations(
      organizations.filter(
        (org) => org.type.id === ORGANIZATION_TYPE_IDS.DEALER
      )
    )
    const isCorporate =
      organizations.find((org) => org.guid === userOrg)?.type.id !==
      ORGANIZATION_TYPE_IDS.DEALER
    setIsCorporateOrg(isCorporate)

    // Also update Organization if we are in Create New mode
    if (!isEdit) {
      setOrganization(dispatchCommercialApp, !isCorporate ? userOrg : null)
    }
  }, [organizations, isEdit])

  /** For New Loan app fetch the Signatures from the API (for existing ones they will be retrieved directly in the object) */
  useEffect(() => {
    const org = hasOrgsAccess
      ? organization
      : organization || userData.organizationId

    if (org && !isEdit) {
      fetchAllLoanSignaturesForOrganization(dispatchCommercialApp, org)
    }
  }, [hasOrgsAccess, isEdit, userData.organizationId, organization])

  /** On Edit Commercial App */
  useEffect(() => {
    if (!Object.keys(loanFormData).length || !isEdit) {
      return
    }

    setAppData(dispatchCommercialApp, loanFormData)
  }, [loanFormData])

  const canCreateEdit = useMemo(
    () =>
      hasAccess(
        userData,
        isEdit
          ? CLAIMS.CAN_EDIT_LOAN_APPLICATIONS
          : CLAIMS.CAN_CREATE_LOAN_APPLICATIONS
      ),
    [userData]
  )

  const onCopyToClipboard = () => {
    copyCommercialAppToClipboard({
      dispatch,
      translate,
      state: commercialAppState,
      canSeeSensitiveData,
    })
  }

  const isValidAddressAPI = async (address, addressType) => {
    let isAddressValid = true
    try {
      const errors = await validateAddress(address)

      if (errors?.length) {
        isAddressValid = false
        errors.forEach((e) => {
          showNotification(dispatch, {
            type: NOTIFICATION_TYPES.NEGATIVE,
            title: `${addressType} address validation failed for ${translate(
              ADDRESS_TYPES_TRANSLATIONS[e]
            )} `,
          })
        })
      }
    } catch {
      return false
    }

    return isAddressValid
  }

  const validateLoanApplication = () => {
    validateCommercialLoanApp(
      dispatchCommercialApp,
      commercialAppState,
      hasOrgsAccess,
      translate,
      async (isValid) => {
        if (isValid) {
          // Validate service address using Validation API
          if (
            !commercialAppState.bypassValidateAddress &&
            !(await isValidAddressAPI(
              commercialAppState.loanInfo.serviceAddress,
              translate('loanApplication.step1.service')
            ))
          ) {
            return
          }

          if (!isEdit) {
            setIsOverlayOpen(true)
          } else {
            submitApplication()
          }
        } else {
          scrollToClosestError(createLoanAppForm.current)
        }
      }
    )
  }

  const submitApplication = (disclosureStatuses) => {
    // passed validation
    const payload = formatSubmitCommercialAppPayload(
      commercialAppState,
      loanApplicationId,
      disclosureStatuses,
      isAuthAgreed,
      isTCAgreed,
      isElectronicAgreed
    )

    setGlobalLoading(dispatch, true)
    submitCommercialLoanApp(dispatch, payload, navigate).finally(() => {
      setGlobalLoading(dispatch, false)

      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: translate('global.successfullySaved'),
      })
    })
  }

  return (
    <CommercialAppContext.Provider
      value={{ state: commercialAppState, dispatch: dispatchCommercialApp }}
    >
      <div className="applicant-info">
        <div className="applicant-info__form-general" ref={createLoanAppForm}>
          {isEdit && (
            <Button
              className="button__clipboard"
              onClick={onCopyToClipboard}
              startIcon={<WhiteClipperIcon />}
            >
              {translate('loanApplication.step1.copyToClipboard')}
            </Button>
          )}

          <CommercialLoanInfo
            organizations={salesOrganisations}
            isCorporateOrg={isCorporateOrg}
            isECG={isECG}
            canSeeSensitiveData={canSeeSensitiveData}
            isEdit={isEdit}
          />

          <Building />

          <BusinessOwner
            isEdit={isEdit}
            canSeeSensitiveData={canSeeSensitiveData}
          />

          {canCreateEdit && (
            <div className={'loan-form__custom-button'}>
              <Button onClick={validateLoanApplication}>
                {translate('loanApplication.step1.saveLoanApp')}
              </Button>
            </div>
          )}
        </div>

        <TermsAndConditionsModals
          onConfirm={(disclosureStatuses) => {
            setIsOverlayOpen(false)
            submitApplication(disclosureStatuses)
          }}
          isTermsOpened={isOverlayOpen}
          setTermsOpened={setIsOverlayOpen}
          hasCoBorrower={false}
          isAuthAgreed={isAuthAgreed}
          setIsAuthAgreed={setIsAuthAgreed}
          isTCAgreed={isTCAgreed}
          setIsTCAgreed={setIsTCAgreed}
          isElectronicAgreed={isElectronicAgreed}
          setIsElectronicAgreed={setIsElectronicAgreed}
        />
      </div>
    </CommercialAppContext.Provider>
  )
}

CreateCommercialLoanApp.propTypes = {
  loanApplicationId: PropTypes.string,
  loanFormData: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}
