import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'
import { getBase64 } from 'common/utils'
import Cookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { COOKIE_NAME } from 'common/constants'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

const { Paper, ProgressIndicator } = components
const { ScanIcon, UploadCloudIcon } = assets

function FileUploadV2({
  onChange,
  multiple = false,
  acceptedFileTypes,
  onDropRejected,
  canUpload,
  title,
  shouldDisplayScanButton,
  onScanIdClick,
  uploadFunctionSettings,
  acceptedFileTypesHelperText = 'DOCX, PNG, JPG, HEIC, or PDF (max. 30MB)',
}) {
  const [names, setNames] = React.useState([])
  const [percentageUploaded, setPercentageUploaded] = React.useState(0)

  const onDrop = useCallback(async (acceptedFiles) => {
    const files = []
    for (const file of acceptedFiles) {
      const content = await getBase64(file)
      const fileData = {
        content,
        name: file.name,
        mimeType: file.type,
      }

      files.push(fileData)

      setNames(files.map((file) => file.name))

      if (uploadFunctionSettings) {
        await uploadFilesProgress(
          uploadFunctionSettings.method,
          uploadFunctionSettings.url,
          { ...uploadFunctionSettings.data, ...fileData },
          onProgress,
          () => {
            uploadFunctionSettings.cb && uploadFunctionSettings.cb()
            setNames([])

            setPercentageUploaded(0)
          }
        )
      }
    }

    if (!uploadFunctionSettings) {
      onChange(multiple ? files : files[0])
    }
  }, [])

  const onProgress = (progress) => {
    const value = Math.round(progress * 100)
    setPercentageUploaded(value)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    onDropRejected: onDropRejected ? onDropRejected : () => {},
  })

  const shouldDisplayDropzone = percentageUploaded || isDragActive

  return (
    <div
      {...getRootProps()}
      className={cx('file-upload-v2', { disabled: !canUpload })}
    >
      <Paper
        sx={{
          boxShadow: 0,
        }}
        className={'paper-v2 upload-card upload-card--no-border'}
      >
        <div className="upload-card__title">
          <h3 style={{ margin: 0, color: colors.grey[700] }}>{title}</h3>

          <div className="upload-card__title__buttons">
            {shouldDisplayScanButton && (
              <Button
                variant={BUTTON_VARIANTS.OUTLINED}
                color={BUTTON_COLORS.INHERIT}
                startIcon={<ScanIcon />}
                onClick={(e) => {
                  e.stopPropagation()
                  onScanIdClick()
                }}
                sx={{ pointerEvents: 'all' }}
              >
                Scan ID
              </Button>
            )}
            {canUpload && (
              <>
                <Button
                  sx={{
                    marginLeft: shouldDisplayScanButton ? '12px' : '0',
                    padding: '8px 18px',
                  }}
                  startIcon={<UploadCloudIcon />}
                >
                  Upload
                </Button>
              </>
            )}
          </div>
        </div>
        {shouldDisplayDropzone && canUpload && (
          <div className="upload-card__dropzone">
            <span>
              {names.length > 0 ? (
                <span className="upload-card__cta">
                  {names.map((n, key) => (
                    <React.Fragment key={key}>{n}</React.Fragment>
                  ))}
                </span>
              ) : (
                <>
                  <span className="upload-card__cta">Click to upload</span> or
                  drag and drop
                </>
              )}
            </span>
            <span className="upload-card__file-extensions">
              {percentageUploaded ? (
                <ProgressIndicator
                  value={percentageUploaded}
                  variant="determinate"
                  label={`${percentageUploaded}%`}
                />
              ) : (
                <>{acceptedFileTypesHelperText}</>
              )}
            </span>
          </div>
        )}
      </Paper>
      <input {...getInputProps()} />
    </div>
  )
}

FileUploadV2.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  canUpload: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  acceptedFileTypes: PropTypes.object,
  acceptedFileTypesHelperText: PropTypes.string,
  shouldDisplayScanButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onDropRejected: PropTypes.func,
  onScanIdClick: PropTypes.func,
  uploadFunctionSettings: PropTypes.shape({
    method: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    data: PropTypes.object,
    cb: PropTypes.func,
  }),
}

export default FileUploadV2

const uploadFilesProgress = (
  method = 'PUT', // 'POST' or 'PUT
  url,
  files,
  onProgress,
  uploadFinishedCallback
) => {
  const cookie = Cookie.get(COOKIE_NAME)
  const xhr = new XMLHttpRequest()
  return new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', (e) => {
      onProgress(e.loaded / e.total)
    })
    xhr.addEventListener('load', () =>
      resolve({ status: xhr.status, body: xhr.responseText })
    )
    xhr.addEventListener('error', () => reject(new Error('File upload failed')))
    xhr.addEventListener('abort', () =>
      reject(new Error('File upload aborted'))
    )
    xhr.open(method, url, true)

    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('x-requestid', uuidv4())
    xhr.setRequestHeader('Authorization', `Bearer ${cookie}`)

    xhr.send(new Blob([JSON.stringify(files)]))
  }).then(() => uploadFinishedCallback && uploadFinishedCallback())
}
