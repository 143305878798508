import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { useLocation, useNavigate } from 'react-router-dom'
import { components, hooks } from '@ElementsCapitalGroup/enium-ui'
import { isEmpty } from 'components/validator'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import { scrollToTop } from 'common/utils'
import { useStore } from 'store'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import Users from 'modules/users'

import OrgDetailsSubOrganizations from './subsections/sub-organizations'
import OrganizationStates from './subsections/states'
import OrganizationLoans from './subsections/loans'
import OrganizationPrioritiseLenders from './subsections/prioritize-lenders'
import OrgDetailsCard from './subsections/card'
import OrgAddEditForm from './new-and-edit-form'
import { getOrganizationByGuid } from '../actions'
import OrganizationStatesAndLoans from './subsections/states-and-loans'

const { Tabs } = components
const { useDialogConfirm } = hooks

const OrganizationDetails = ({
  orgId,
  orgData,
  onAddOrganization,
  onEditOrganization,
  onDeleteSelf,
  onSubOrgDelete,
  userData,
  orgTypes,
  orgStatuses,
  setLoading,
}) => {
  const { state, dispatch } = useStore()
  const { hash, pathname } = useLocation()
  const navigate = useNavigate()
  const { userRoles: roles } = state.orgManagement
  const confirm = useDialogConfirm()
  const [editFormData, setEditFormData] = useState(null)
  const isNew = editFormData === 'new'
  const isCorporate = orgData?.type.id === ORGANIZATION_TYPE_IDS.CORPORATE
  const isLender = orgData?.type.id === ORGANIZATION_TYPE_IDS.LENDER
  const isInstaller = orgData?.type.id === ORGANIZATION_TYPE_IDS.INSTALLER
  const isDealer = orgData?.type.id === ORGANIZATION_TYPE_IDS.DEALER
  const canDeleteOrg = hasAccess(
    state.session.userData,
    CLAIMS.CAN_DELETE_ORGANIZATIONS
  )
  const canViewStatesAndLoans = hasAccess(
    state.session.userData,
    CLAIMS.CAN_VIEW_INSTALLERS_DEALERS_LOAN_PRODUCT_STATES_ASSOCIATIONS
  )
  const isBeta = pathname.indexOf('-beta') > -1

  useEffect(() => {
    if (hash === '#edit' && pathname) {
      navigate(pathname, { replace: true })
      openEditForm(orgData)
    }
  }, [hash, pathname])

  const openEditForm = (data) => {
    if (data) {
      setTimeout(() => scrollToTop(), 50)
    }
    setEditFormData(data)
  }

  const tabs = useMemo(() => {
    if (isEmpty(orgData)) {
      return null
    }
    const { isRoot } = orgData
    const tabs = []
    if (isBeta ? isCorporate : !isLender) {
      tabs.push({
        label: 'Sub Organizations',
        value: TABS.SUB_ORGS,
        children: (
          <OrgDetailsSubOrganizations
            dispatch={dispatch}
            data={orgData.children || []}
            onSubOrgDelete={onSubOrgDelete}
            onNewSubOrg={() => openEditForm('new')}
            onEditSubOrg={(orgGuid) => {
              const childOrg = orgData.children.find(
                (el) => el.guid === orgGuid
              )
              if (childOrg) {
                // This is because of a BE limitation - when the Org is type Lender, we don't have all the details populated, and have to do a GET
                if (childOrg.type.id === ORGANIZATION_TYPE_IDS.LENDER) {
                  setLoading(true)
                  getOrganizationByGuid(childOrg.guid)
                    .then(openEditForm)
                    .finally(() => setLoading(false))
                } else {
                  openEditForm(childOrg)
                }
              }
            }}
          />
        ),
      })
    }
    if (hasAccess(userData, CLAIMS.CAN_EDIT_SALES_USERS)) {
      tabs.push({
        label: 'Users',
        value: TABS.USERS,
        children: (
          <Users
            orgId={orgId}
            orgData={orgData}
            roles={roles}
            dispatch={dispatch}
          />
        ),
      })
    }
    if (!isBeta && !isRoot && !isCorporate && !isLender) {
      tabs.push({
        label: 'Loans',
        value: TABS.LOANS,
        children: (
          <OrganizationLoans
            orgData={orgData}
            onEditOrganization={onEditOrganization}
            dispatch={dispatch}
            setLoading={setLoading}
          />
        ),
      })
    }
    if (!isBeta && !isRoot && !isLender && !isCorporate) {
      tabs.push({
        label: 'States',
        value: TABS.STATES,
        children: (
          <OrganizationStates
            orgData={orgData}
            onEditOrganization={onEditOrganization}
            dispatch={dispatch}
            setLoading={setLoading}
          />
        ),
      })
    }
    if (isBeta && canViewStatesAndLoans && (isDealer || isInstaller)) {
      tabs.push({
        label: 'States & Loans',
        value: TABS.STATES_AND_LOANS,
        children: (
          <OrganizationStatesAndLoans
            orgData={orgData}
            userData={userData}
            dispatch={dispatch}
            setLoading={setLoading}
          />
        ),
      })
    }
    if ((isBeta && isInstaller) || (!isBeta && isDealer)) {
      tabs.push({
        label: 'Prioritise Lenders',
        value: TABS.LENDERS,
        children: (
          <OrganizationPrioritiseLenders
            orgData={orgData}
            dispatch={dispatch}
            setLoading={setLoading}
          />
        ),
      })
    }
    return tabs
  }, [userData, orgId, orgData, roles, isLender, isCorporate])

  const onSave = (data) => {
    let promise
    if (isNew) {
      promise = onAddOrganization({ ...data, parentId: orgData.guid })
    } else {
      if (!data.organizationStatesIds) {
        data.organizationStatesIds = data.organizationStates.map((el) => el.id)
      }
      promise = onEditOrganization(data)
    }
    promise
      .then((response) => {
        if (response) {
          showNotification(dispatch, {
            title: `Successfully ${
              isNew ? 'created' : 'updated'
            } organization "${response.name}"`,
          })
          setEditFormData(null)
        }
        return response
      })
      .catch((err) => {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: `An error occurred while ${
            isNew ? 'creating' : 'updating'
          } the organization. ${err.title}`,
        })
      })
  }

  const onOrganizationDelete = (guid) => {
    confirm({ confirmationText: 'Yes' })
      .then(() => {
        if (guid === orgData.guid) {
          onDeleteSelf(guid)
        } else {
          onSubOrgDelete(guid)
        }
      })
      .catch(() => {})
  }

  return (
    <>
      {editFormData ? (
        <OrgAddEditForm
          orgTypes={orgTypes}
          orgStatuses={orgStatuses}
          parentOrg={orgData}
          isNew={isNew}
          orgData={isNew ? null : editFormData}
          onSave={onSave}
          onCancel={() => setEditFormData(null)}
          userData={userData}
        />
      ) : (
        <OrgDetailsCard
          orgData={orgData}
          onDeleteSelf={onOrganizationDelete}
          onEdit={() => setEditFormData(orgData)}
          canDeleteOrg={canDeleteOrg}
        />
      )}

      {tabs && (
        <Tabs
          key={tabs.length}
          items={tabs}
          sx={{ marginTop: '32px' }}
          panelProps={{
            sx: {
              marginTop: '32px',
              padding: 0,
            },
          }}
        />
      )}
    </>
  )
}

const TABS = {
  SUB_ORGS: 'SUB_ORGS',
  USERS: 'USERS',
  STATES: 'STATES',
  LOANS: 'LOANS',
  STATES_AND_LOANS: 'STATES_AND_LOANS',
  ASSOCIATIONS: 'ASSOCIATIONS',
  LENDERS: 'LENDERS',
}

OrganizationDetails.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgData: PropTypes.object.isRequired,
  onAddOrganization: PropTypes.func.isRequired,
  onEditOrganization: PropTypes.func.isRequired,
  onDeleteSelf: PropTypes.func.isRequired,
  onSubOrgDelete: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  orgTypes: PropTypes.array.isRequired,
  orgStatuses: PropTypes.array.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OrganizationDetails
