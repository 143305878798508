import {
  STATES_ORDER,
  STATES_ORDER_COMMERCIAL,
  STATE_ID_TO_NAME,
  LOAN_APPLICATION_STATES_IDS,
  ATTACHMENT_STATUS_IDS,
} from '../constants'
import { NTP_SECTIONS_IDS } from './constants'

export const areNtpsReadyForSubmission = (
  loanApplicationStateId,
  isInitialNtp,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? loanApplicationStateId >= statesOrder.CommercialDocumentationReview
    : isInitialNtp
    ? loanApplicationStateId >= statesOrder.DocumentsSigned
    : loanApplicationStateId >= statesOrder.IFGranted &&
      loanApplicationStateId !== statesOrder.NTPReSubmitted &&
      loanApplicationStateId !== statesOrder.NTPRejected
}

export const areNtpSubmitted = (
  loanApplicationStateId,
  isInitialNtp,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? true
    : isInitialNtp
    ? loanApplicationStateId >= statesOrder.NTPSubmitted &&
      loanApplicationStateId !== statesOrder.NTPRejected
    : loanApplicationStateId >= statesOrder.FNTPSubmitted &&
      loanApplicationStateId !== statesOrder.FNTPRejected
}

export const areNtpsApproved = (
  loanApplicationStateId,
  isInitialNtp,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? loanApplicationStateId >= statesOrder.CommercialStipulationsApproved
    : isInitialNtp
    ? loanApplicationStateId >= statesOrder.NTPApproved &&
      loanApplicationStateId !== statesOrder.NTPReSubmitted &&
      loanApplicationStateId !== statesOrder.NTPRejected
    : loanApplicationStateId >= statesOrder.FNTPApproved &&
      loanApplicationStateId !== statesOrder.FNTPReSubmitted &&
      loanApplicationStateId !== statesOrder.FNTPRejected
}

export const areFundsRequested = (
  loanApplicationStateId,
  isInitial,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? null
    : isInitial
    ? loanApplicationStateId >= statesOrder.IFRequested &&
      loanApplicationStateId !== statesOrder.NTPRejected &&
      loanApplicationStateId !== statesOrder.NTPReSubmitted
    : loanApplicationStateId >= statesOrder.FFRequested &&
      loanApplicationStateId !== statesOrder.FNTPRejected &&
      loanApplicationStateId !== statesOrder.FNTPReSubmitted
}

export const areFundsGranted = (
  loanApplicationStateId,
  isInitial,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? null
    : isInitial
    ? loanApplicationStateId >= statesOrder.IFGranted &&
      loanApplicationStateId !== statesOrder.NTPRejected &&
      loanApplicationStateId !== statesOrder.NTPReSubmitted
    : loanApplicationStateId >= statesOrder.FFGranted &&
      loanApplicationStateId !== statesOrder.FNTPRejected &&
      loanApplicationStateId !== statesOrder.FNTPReSubmitted
}

export const getNtpTypeId = (loanApplicationStateId, isCommercial = false) => {
  if (isCommercial) {
    return NTP_SECTIONS_IDS.COMMERCIAL
  }

  const stateName = STATE_ID_TO_NAME[loanApplicationStateId]

  const step = STATES_ORDER[stateName]

  return step < STATES_ORDER.IFGranted ||
    step === STATES_ORDER.NTPReSubmitted ||
    step === STATES_ORDER.NTPRejected
    ? NTP_SECTIONS_IDS.INITIAL
    : NTP_SECTIONS_IDS.FINAL
}

export const areNtpAttachmentsApproved = (ntps) => {
  // Each group of NTPs has to have a status (approved, rejected, needs review) and at least one approved userNTP
  for (const ntp of ntps) {
    let atLeastOneApproved = false

    for (const userNTP of ntp.userNTPs) {
      if (userNTP.statusId === ATTACHMENT_STATUS_IDS.APPROVED) {
        atLeastOneApproved = true
      } else if (userNTP.statusId === null || userNTP.statusId === undefined) {
        // Make sure all have a valid status
        return false
      }
    }

    // If all have a valid status, make sure at least one is approved
    if (!atLeastOneApproved) {
      return false
    }
  }

  return true
}

export const getNtpStates = (isInitial, isCommercial) => {
  if (isCommercial) {
    return [
      LOAN_APPLICATION_STATES_IDS.COMMERCIAL_DOCUMENTATION_REVIEW,
      LOAN_APPLICATION_STATES_IDS.COMMERCIAL_STIPULATIONS_APPROVED,
    ]
  }

  if (isInitial) {
    return [
      LOAN_APPLICATION_STATES_IDS.NTP_SUBMITTED,
      LOAN_APPLICATION_STATES_IDS.NTP_APPROVED,
      LOAN_APPLICATION_STATES_IDS.IF_REQUESTED,
      LOAN_APPLICATION_STATES_IDS.INITIAL_NTP_REJECTED,
      LOAN_APPLICATION_STATES_IDS.INITIAL_NTP_RESUBMITTED,
    ]
  }

  return [
    LOAN_APPLICATION_STATES_IDS.IF_GRANTED,
    LOAN_APPLICATION_STATES_IDS.FNTP_SUBMITTED,
    LOAN_APPLICATION_STATES_IDS.FNTP_APPROVED,
    LOAN_APPLICATION_STATES_IDS.FF_REQUESTED,
    LOAN_APPLICATION_STATES_IDS.FF_GRANTED,
    LOAN_APPLICATION_STATES_IDS.FINAL_NTP_REJECTED,
    LOAN_APPLICATION_STATES_IDS.FINAL_NTP_RESUBMITTED,
  ]
}
