import { useEffect, useMemo, useState } from 'react'
import Cookie from 'js-cookie'
import { useStore } from 'store'
import { COOKIE_NAME, REFRESH_COOKIE_NAME } from 'common/constants'
import { updateAccessTokenIfExpires } from 'common/access'

/** User session management */
const useSession = () => {
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const [isCheckingToken, setIsCheckingToken] = useState(true)
  const [isTokenChecked, setIsTokenChecked] = useState(false)

  const checkAccessToken = async () => {
    await updateAccessTokenIfExpires(dispatch, setIsCheckingToken)
  }

  useEffect(() => {
    const accessTokenExists = !!Cookie.get(COOKIE_NAME)
    const refreshTokenExists = !!Cookie.get(REFRESH_COOKIE_NAME)

    if (!accessTokenExists && !refreshTokenExists) {
      setIsCheckingToken(false)
      setIsTokenChecked(true)
    } else if (!accessTokenExists && refreshTokenExists) {
      checkAccessToken().then(() => {
        setIsTokenChecked(true)
      })
    } else {
      setIsCheckingToken(false)
      setIsTokenChecked(true)
    }
  }, [])

  const isLoggedIn = useMemo(() => {
    const accessTokenExists = !!Cookie.get(COOKIE_NAME)
    const refreshTokenExists = !!Cookie.get(REFRESH_COOKIE_NAME)

    if (accessTokenExists) {
      setIsTokenChecked(true)
      return true
    }

    return isTokenChecked && (accessTokenExists || refreshTokenExists)
  }, [Cookie.get(COOKIE_NAME), Cookie.get(REFRESH_COOKIE_NAME), isTokenChecked])

  return [isLoggedIn, userData, isCheckingToken]
}

export default useSession
