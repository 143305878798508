import Api from 'easy-fetch-api'

import { mapLoanProductsForClient } from 'modules/loan-application/utils'
import { fetchCommercialLoanProductsForOrganization } from 'modules/loan-product/actions'
import {
  formatCommercialAppServerDataToState,
  validateCommercialApp,
} from '../utils'

export const ACTIONS = {
  INIT_APP: 'commercialApp.init',
  SET_BUILDING_FIELD: 'commercialApp.setBuildingField',
  SET_BUSINESS_OWNER_FIELD: 'commercialApp.setBusinessOwnerField',
  ADD_BUSINESS_OWNER: 'commercialApp.addBusinessOwner',
  REMOVE_BUSINESS_OWNER: 'commercialApp.removeBusinessOwner',
  SET_LOAN_INFO_FIELD: 'commercialApp.setLoanInfoField',
  SET_ALL_LOAN_SIGNATURES: 'commercialApp.setAllLoanSignatures',
  SET_ERRORS: 'commercialApp.setErrors',
  TOGGLE_BYPASS_ADDRESS_VALIDATION:
    'commercialApp.toggleBypassAddressValidation',
  SET_TYPES_OF_BUSINESS: 'commercialApp.setTypesOfBusiness',
  SET_ORGANIZATION: 'commercialApp.setOrganization',
  TOGGLE_OWNER_BYPASS_ADDRESS_VALIDATION:
    'commercialApp.toggleOwnerBypassAddressValidation',
  SET_APP_DATA: 'commercialApp.setAppData',
}

export const initCommercialApp = (dispatch) => {
  dispatch({
    type: ACTIONS.INIT_APP,
  })
}

export const setErrors = (dispatch, value, objectKey, objectSubKey) => {
  dispatch({
    type: ACTIONS.SET_ERRORS,
    objectKey,
    objectSubKey,
    value,
  })
}

export const setAppData = (dispatch, loanData) => {
  const data = formatCommercialAppServerDataToState(loanData)

  dispatch({
    type: ACTIONS.SET_APP_DATA,
    data,
  })
}

export const setOrganization = (dispatch, organization) => {
  dispatch({
    type: ACTIONS.SET_ORGANIZATION,
    organization,
  })
}

export const validateCommercialLoanApp = (
  dispatch,
  loanAppState,
  hasOrgsAccess,
  translate,
  isValidCb
) => {
  const {
    isValid,
    infoSectionErrors,
    buildingSectionErrors,
    businessOwnersSection,
  } = validateCommercialApp(loanAppState, hasOrgsAccess, translate)

  setErrors(dispatch, { ...infoSectionErrors }, 'loanInfo')
  setErrors(dispatch, { ...buildingSectionErrors }, 'building')
  setErrors(dispatch, [...businessOwnersSection], 'businessOwners')

  isValidCb(isValid)
}

export const fetchAllLoanSignaturesForOrganization = async (dispatch, org) => {
  const result = await fetchCommercialLoanProductsForOrganization(org)
  const allLoanSignatures = mapLoanProductsForClient(result)

  dispatch({
    type: ACTIONS.SET_ALL_LOAN_SIGNATURES,
    allLoanSignatures: allLoanSignatures,
    loanSignatures: [],
  })
}

export const toggleBypassAddressValidation = (dispatch) => {
  dispatch({
    type: ACTIONS.TOGGLE_BYPASS_ADDRESS_VALIDATION,
  })
}

/**
 * BUILDING ACTIONS
 */
export const setBuildingField = (dispatch, fieldKey, fieldValue) => {
  dispatch({
    type: ACTIONS.SET_BUILDING_FIELD,
    fieldKey,
    fieldValue,
  })
}

/**
 * BUSINESS OWNER ACTIONS
 */
export const setBusinessOwnerField = (
  dispatch,
  fieldKey,
  fieldValue,
  ownerId
) => {
  dispatch({
    type: ACTIONS.SET_BUSINESS_OWNER_FIELD,
    fieldKey,
    fieldValue,
    ownerId,
  })
}

export const addBusinessOwner = (dispatch) => {
  dispatch({
    type: ACTIONS.ADD_BUSINESS_OWNER,
  })
}

export const removeBusinessOwner = (dispatch, ownerId) => {
  dispatch({
    type: ACTIONS.REMOVE_BUSINESS_OWNER,
    ownerId,
  })
}

export const toggleOwnerBypassAddressValidation = (dispatch, ownerId) => {
  dispatch({
    type: ACTIONS.TOGGLE_OWNER_BYPASS_ADDRESS_VALIDATION,
    ownerId,
  })
}

/**
 * LOAN INFO ACTIONS
 */
export const setLoanInfoField = (dispatch, fieldKey, fieldValue) => {
  dispatch({
    type: ACTIONS.SET_LOAN_INFO_FIELD,
    fieldKey,
    fieldValue,
  })
}

export const getTypesOfBusiness = (dispatch) => {
  Api.get({ url: '/LoanApplication/business-types' })
    .then((res) => {
      dispatch({
        type: ACTIONS.SET_TYPES_OF_BUSINESS,
        typesOfBusiness: res,
      })
    })
    .catch(console.error)
}
