import React from 'react'

export const CommercialAppContext = React.createContext(undefined)

export const useCommercialAppContext = () => {
  const context = React.useContext(CommercialAppContext)

  if (context === undefined) {
    throw Error(
      'useCommercialAppContext must be used as a ancestor of CommercialAppContext.Provider in order to work.'
    )
  }

  return context
}
