import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'

import { formatAddress } from 'common/utils'
import LocationSearchInput from 'components/location-search-input'
import ApplicantInfoAddress from 'modules/loan-application/service-details'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import Checkbox from 'components/checkbox'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'
import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

const { Button } = components
const { PlusIcon } = assets

export const AggregatedAddress = ({
  address,
  label,
  errorObjectName = 'serviceAddress',
  errors,
  hideBypassValidation,
  bypassValidateAddress,
  areInputsDisabled,
  hasErrors,
  displayExpandedAddressFields,
  handleSetAddress,
  onAddressSelected,
  toggleDisplayExpandedAddressFields,
  toggleBypassAddressValidation,
  setErrors,
}) => {
  const { t: translate } = useTranslation()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  return (
    <div className="service-address">
      <LocationSearchInput
        callback={onAddressSelected}
        defaultValue={formatAddress(address)}
        label={label}
        validate={() =>
          hasErrors
            ? translate('loanApplication.step1.address.errors.invalidAddress')
            : null
        }
        disabled={areInputsDisabled}
      />

      <Button
        startIcon={<PlusIcon />}
        sx={{ justifySelf: 'flex-start' }}
        variant={
          isTabletView ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.TEXT
        }
        color={isTabletView ? BUTTON_COLORS.SECONDARY : BUTTON_COLORS.PRIMARY}
        onClick={toggleDisplayExpandedAddressFields}
      >
        {translate('loanApplication.step1.address.addManually')}
      </Button>

      {displayExpandedAddressFields && (
        <div>
          <ApplicantInfoAddress
            addressDetails={address}
            setAddressDetails={handleSetAddress}
            errors={errors}
            setErrors={setErrors}
            errorObjectName={errorObjectName}
          />

          {!hideBypassValidation && (
            <AccessWrapper claims={CLAIMS.CAN_BYPASS_ADDRESS_VALIDATION}>
              <Checkbox
                label={translate(
                  'loanApplication.step1.bypassAddressValidation'
                )}
                onClick={() =>
                  toggleBypassAddressValidation &&
                  toggleBypassAddressValidation()
                }
                checked={bypassValidateAddress}
                uncentered
              />
            </AccessWrapper>
          )}
        </div>
      )}
    </div>
  )
}

AggregatedAddress.propTypes = {
  address: PropTypes.object,
  label: PropTypes.string,
  errors: PropTypes.object.isRequired,
  errorObjectName: PropTypes.string,
  hideBypassValidation: PropTypes.bool,
  bypassValidateAddress: PropTypes.bool,
  areInputsDisabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  displayExpandedAddressFields: PropTypes.bool,
  handleSetAddress: PropTypes.func.isRequired,
  onAddressSelected: PropTypes.func.isRequired,
  toggleDisplayExpandedAddressFields: PropTypes.func.isRequired,
  toggleBypassAddressValidation: PropTypes.func,
  setErrors: PropTypes.func.isRequired,
}
