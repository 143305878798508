import { ADDRESS_DETAILS, GENERAL_ADDRESS_ERRORS } from 'common/constants'

import { ACTIONS } from './actions'
import {
  DEFAULT_BUSINESS_OWNER,
  DEFAULT_BUSINESS_OWNER_ERRORS,
} from './constants'

export const initialState = {
  loading: false,
  typesOfBusiness: [],
  bypassValidateAddress: false,
  areInputsDisabled: false,
  allLoanSignatures: [],
  organization: null,
  loanInfo: {
    legalCompanyName: '',
    businessTypeId: '',
    einNumber: '',
    businessStartDate: '',
    annualGrossRevenue: '',
    mailingAddress: ADDRESS_DETAILS,
    businessPhone: '',
    mobilePhone: '',
    emailAddress: '',
    natureOfBusiness: '',
    debtServiceSchedule: '',
    // top section
    loanSignatures: [],
    loanAmount: '',
    serviceAddress: ADDRESS_DETAILS,
  },
  building: {
    buildingDetailsId: '',
    isLease: false,
    // own
    lenderName: '',
    lenderAddress: ADDRESS_DETAILS,
    lenderPhoneNumber: '',
    estimatedPropertyValue: '',
    monthlyMortgagePayment: '',
    remainingLoanBalance: '',
    // lease
    landlordName: '',
    landlordAddress: ADDRESS_DETAILS,
    landlordPhoneNumber: '',
    monthlyRent: '',
    monthsRemainingOnLease: '',
  },
  businessOwners: [DEFAULT_BUSINESS_OWNER],
  errors: {
    organization: '',
    loanInfo: {
      legalCompanyName: '',
      businessTypeId: '',
      einNumber: '',
      businessStartDate: '',
      annualGrossRevenue: '',
      mailingAddress: { ...GENERAL_ADDRESS_ERRORS },
      businessPhone: '',
      mobilePhone: '',
      emailAddress: '',
      natureOfBusiness: '',
      debtServiceSchedule: '',
      // top section
      loanSignatures: '',
      loanAmount: '',
      serviceAddress: { ...GENERAL_ADDRESS_ERRORS },
    },
    building: {
      lenderFirstName: '',
      lenderLastName: '',
      lenderAddress: { ...GENERAL_ADDRESS_ERRORS },
      lenderPhoneNumber: '',
      estimatedPropertyValue: '',
      monthlyMortgagePayment: '',
      remainingLoanBalance: '',
      // lease
      landlordName: '',
      landlordAddress: { ...GENERAL_ADDRESS_ERRORS },
      landlordPhoneNumber: '',
      monthlyRent: '',
      monthsRemainingOnLease: '',
    },
    businessOwners: [DEFAULT_BUSINESS_OWNER_ERRORS],
  },
}

export const commercialLoanAppReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT_APP:
      return {
        ...state,
        loading: false,
      }
    case ACTIONS.SET_BUILDING_FIELD:
      return {
        ...state,
        building: {
          ...state.building,
          [action.fieldKey]: action.fieldValue,
        },
      }

    case ACTIONS.SET_BUSINESS_OWNER_FIELD: {
      return {
        ...state,
        businessOwners: state.businessOwners.map((owner, index) => {
          if (index === action.ownerId) {
            return {
              ...owner,
              [action.fieldKey]: action.fieldValue,
            }
          }
          return owner
        }),
      }
    }

    case ACTIONS.ADD_BUSINESS_OWNER: {
      return {
        ...state,
        businessOwners: [...state.businessOwners, DEFAULT_BUSINESS_OWNER],
        errors: {
          ...state.errors,
          businessOwners: [
            ...state.errors.businessOwners,
            DEFAULT_BUSINESS_OWNER_ERRORS,
          ],
        },
      }
    }

    case ACTIONS.REMOVE_BUSINESS_OWNER: {
      return {
        ...state,
        businessOwners: state.businessOwners.filter(
          (_, index) => index !== action.ownerId
        ),
        errors: {
          ...state.errors,
          businessOwners: state.errors.businessOwners.filter(
            (_, index) => index !== action.ownerId
          ),
        },
      }
    }

    case ACTIONS.TOGGLE_OWNER_BYPASS_ADDRESS_VALIDATION: {
      return {
        ...state,
        businessOwners: state.businessOwners.map((owner, index) => {
          if (index === action.ownerId) {
            return {
              ...owner,
              bypassValidateAddress: !owner.bypassValidateAddress,
            }
          }

          return owner
        }),
      }
    }

    case ACTIONS.SET_LOAN_INFO_FIELD:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          [action.fieldKey]: action.fieldValue,
        },
      }

    case ACTIONS.SET_ALL_LOAN_SIGNATURES:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanSignatures: action.loanSignatures,
        },
        allLoanSignatures: action.allLoanSignatures,
      }

    case ACTIONS.SET_ERRORS:
      if (action.objectSubKey) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.objectKey]: {
              ...state.errors[action.objectKey],
              [action.objectSubKey]: action.value,
            },
          },
        }
      }
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.objectKey]: action.value,
        },
      }

    case ACTIONS.TOGGLE_BYPASS_ADDRESS_VALIDATION:
      return {
        ...state,
        bypassValidateAddress: !state.bypassValidateAddress,
      }
    case ACTIONS.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      }

    case ACTIONS.SET_TYPES_OF_BUSINESS: {
      return {
        ...state,
        typesOfBusiness: action.typesOfBusiness,
      }
    }

    case ACTIONS.SET_APP_DATA: {
      return {
        ...state,
        ...action.data,
        building: {
          ...state.building,
          ...action.data.building,
        },
        errors: {
          ...state.errors,
          // set default errors for existing business owners
          businessOwners: [
            ...new Array(action.data.businessOwners.length).fill(
              DEFAULT_BUSINESS_OWNER_ERRORS
            ),
          ],
        },
      }
    }

    default:
      return state
  }
}
