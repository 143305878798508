import React, { useMemo, useState } from 'react'
import { useStore } from 'store'

import { UW_APPROVAL_STATUS_IDS } from 'modules/uw-hub/constants'
import Loader, { LOADER_SIZE } from 'components/loader'
import {
  getNtpTypeId,
  areNtpAttachmentsApproved,
} from 'modules/loan-application/ntp/helpers'

import { NTP_SECTIONS_IDS } from 'modules/loan-application/ntp/constants'
import LoanApplicationDetails from './loan-application-details/index'
import SolarContracts from './solar-contracts/index'
import CreditReports from './credit-reports'
import StipulationSection from './stipulations'
import PropertyTitle from './property-title/index'
import Notes from './notes'
import Income from './income'
import Evaluation from './evaluation'
import DataReview from './data-review'
import General from './general'
import Employment from './employment'
import Identification from './identification'
import IncomeCalculator from './income-calculator'

import './style.scss'
import UWHistory from './history'

const UwHubModule = () => {
  const { state } = useStore()
  const uwHubData = state.uwHub
  const { loanApplicationId, loanApplication, loading } = uwHubData
  const [showIncomeCalculator, setShowIncomeCalculator] = useState(false)

  const areNtpsApproved = useMemo(() => {
    return getNtpTypeId(loanApplication.loanApplicationStateId) ===
      NTP_SECTIONS_IDS.INITIAL
      ? areNtpAttachmentsApproved(uwHubData.stipulations)
      : areNtpAttachmentsApproved(uwHubData.finalStipulations)
  }, [
    loanApplication.loanApplicationStateId,
    JSON.stringify(uwHubData.stipulations),
    JSON.stringify(uwHubData.finalStipulations),
  ])

  const canApproveStipulations = useMemo(() => {
    const sectionsApprovalIds = [
      uwHubData.reviewData.uwApprovalStatusId,
      uwHubData.generalData.uwApprovalStatusId,
      uwHubData.employmentData.uwApprovalStatusId,
      uwHubData.incomeData.uwApprovalStatusId,
      uwHubData.identificationData.uwApprovalStatusId,
    ]

    const areSectionsApproved = sectionsApprovalIds.every(
      (s) => s === UW_APPROVAL_STATUS_IDS.Approved
    )

    return areSectionsApproved && areNtpsApproved
  }, [
    uwHubData.reviewData.uwApprovalStatusId,
    uwHubData.generalData.uwApprovalStatusId,
    uwHubData.employmentData.uwApprovalStatusId,
    uwHubData.incomeData.uwApprovalStatusId,
    uwHubData.identificationData.uwApprovalStatusId,
    areNtpsApproved,
  ])

  return (
    <div className="uw-hub-page">
      {loading ? (
        <Loader size={LOADER_SIZE.MEDIUM} center={true} />
      ) : (
        <>
          <LoanApplicationDetails
            loanApplicationId={loanApplicationId}
            data={loanApplication}
            canApproveStipulations={canApproveStipulations}
          />
          <DataReview
            loanApplicationId={loanApplicationId}
            data={uwHubData.reviewData}
          />
          <General
            loanApplicationId={loanApplicationId}
            data={uwHubData.generalData}
          />
          <Employment
            loanApplicationId={loanApplicationId}
            data={uwHubData.employmentData}
          />
          <Income
            loanApplicationId={loanApplicationId}
            borrowerId={loanApplication.borrowerId}
            coBorrowerId={loanApplication.coBorrowerId}
            incomeData={uwHubData.incomeData}
            openIncomeCalculator={() => setShowIncomeCalculator(true)}
          />
          <Identification
            loanApplicationId={loanApplicationId}
            data={uwHubData.identificationData}
          />
          <CreditReports data={uwHubData.creditReports} />
          <Evaluation
            evaluationSessions={uwHubData.evaluationSessions}
            selectedLoanProductId={loanApplication.selectedLoanProductId}
          />
          <SolarContracts
            loanApplicationId={loanApplicationId}
            data={uwHubData.solarContracts}
          />
          <PropertyTitle propertyTitle={uwHubData.propertyTitle} />
          <StipulationSection
            stipulations={uwHubData.stipulations}
            loanApplicationId={loanApplicationId}
            loanApplicationStateId={loanApplication.loanApplicationStateId}
            serviceAddress={loanApplication.serviceAddress}
            estimatedCombinedIncomeToBeProven={
              loanApplication.estimatedCombinedIncomeToBeProven
            }
            isInitialNtp={true}
          />
          {uwHubData.finalStipulations.length > 0 && (
            <StipulationSection
              stipulations={uwHubData.finalStipulations}
              loanApplicationId={loanApplicationId}
              loanApplicationStateId={loanApplication.loanApplicationStateId}
              serviceAddress={loanApplication.serviceAddress}
              estimatedCombinedIncomeToBeProven={
                loanApplication.estimatedCombinedIncomeToBeProven
              }
              isInitialNtp={false}
            />
          )}
          <Notes
            loanApplicationId={loanApplicationId}
            notes={uwHubData.notes}
          />
          <UWHistory loanApplicationId={loanApplicationId} />

          <IncomeCalculator
            isOpen={showIncomeCalculator}
            onClose={() => setShowIncomeCalculator(false)}
          />
        </>
      )}
    </div>
  )
}

export default UwHubModule
